import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function AktionMensch() {
  return (
    <StaticImage
      src="../../../assets/image/aktion-mensch-2023.png"
      alt="Aktion Mensch"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      width={100}
      height={100}
      loading="lazy"
    />
  );
}
