import { useStaticQuery, graphql } from 'gatsby';

export function useStoreConfig() {
  const data = useStaticQuery<GatsbyTypes.StoreConfigQuery>(graphql`
    query StoreConfig {
      sus {
        storeConfig {
          base_currency_code
          id
          welcome
          weight_unit
          website_id
          title_suffix
          title_separator
          title_prefix
          timezone
          store_name
          logo_alt
          logo_height
          logo_width
          absolute_footer
          base_link_url
          base_media_url
          base_static_url
          base_url
          catalog_default_sort_by
          cms_home_page
          cms_no_cookies
          cms_no_route
          code
          copyright
          default_description
          default_display_currency_code
          default_keywords
          default_title
          demonotice
          front
          grid_per_page
          head_includes
          grid_per_page_values
          head_shortcut_icon
          header_logo_src
          locale
          no_route
          product_fixed_product_tax_display_setting
          root_category_id
          secure_base_link_url
          secure_base_media_url
          secure_base_static_url
          secure_base_url
          show_cms_breadcrumbs
          sales_fixed_product_tax_display_setting
          product_url_suffix
          list_per_page_values
          list_per_page
          list_mode
          category_url_suffix
          category_fixed_product_tax_display_setting
        }
      }
    }
  `);

  return data.sus.storeConfig;
}
