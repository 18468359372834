import {
  CART_ADD_SIMPLEPRODUCT,
  CART_CREATE_EMPTY,
  CART_GET_CART,
  CART_GET_CUSTOMERCART,
  CART_MERGE,
  CART_REMOVEITEM,
  CART_UPDATEITEM,
  CART_SET_SHIPPINGMETHODS,
  CART_SET_PAYMENTMETHOD,
  CART_SET_BILLINGADDRESS,
  CART_SET_SHIPPINGADDRESSES,
  CART_SET_EMAIL,
  CART_CREATE_PAYPALEXPRESSTOKEN,
  CART_GET_CHECKOUTAGREEMENTS,
  PLACE_ORDER,
} from '..';
import {
  AddProduct,
  AddProductVariables,
  CreateEmptyCart,
  GetCart,
  GetCartVariables,
  GetCustomerCart,
  MergeCart,
  MergeCartVariables,
  RemoveCartItem,
  RemoveCartItemVariables,
  SetBillingAddressVariables,
  SetShippingAddressesOnCart,
  SetShippingAddressesOnCartVariables,
  SetPaymentMethodOnCartInput,
  SetShippingMethodsOnCartInput,
  SetShippingMethodsOnCart,
  SetShippingMethodsOnCartVariables,
  UpdateCartItems,
  UpdateCartItemsInput,
  UpdateCartItemsVariables,
  SetPaymentMethodOnCart,
  SetPaymentMethodOnCartVariables,
  SetBillingAddress,
  SetBillingAddressOnCartInput,
  SetShippingAddressesOnCartInput,
  SetEmailOnCart,
  SetEmailOnCartVariables,
  SetGuestEmailOnCartInput,
  CreatePaypayExpressToken,
  CreatePaypayExpressTokenVariables,
  PaypalExpressTokenInput,
  PlaceOrder,
  PlaceOrderVariables,
  PlaceOrderInput,
  CheckoutAgreements,
} from '../../../generated/api.types';
import { gqlClient } from '../client/sus-graphql-client';

const createCart = () =>
  gqlClient.mutate<CreateEmptyCart, undefined>({
    mutation: CART_CREATE_EMPTY,
    errorPolicy: 'all',
  });

const getCart = (cartId: string) =>
  gqlClient.query<GetCart, GetCartVariables>({
    query: CART_GET_CART,
    variables: {
      cartId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

const getCustomerCart = () =>
  gqlClient.query<GetCustomerCart, undefined>({
    query: CART_GET_CUSTOMERCART,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

const addItem = ({ cartId, quantity, sku, options }: AddProductVariables) =>
  gqlClient.mutate<AddProduct, AddProductVariables>({
    mutation: CART_ADD_SIMPLEPRODUCT,
    variables: {
      cartId,
      quantity,
      sku,
      options,
    },
    errorPolicy: 'all',
  });

const updateItem = (input: UpdateCartItemsInput) =>
  gqlClient.mutate<UpdateCartItems, UpdateCartItemsVariables>({
    mutation: CART_UPDATEITEM,
    variables: { input },
    errorPolicy: 'all',
  });

const removeItem = (cartId: string, itemId: number) =>
  gqlClient.mutate<RemoveCartItem, RemoveCartItemVariables>({
    mutation: CART_REMOVEITEM,
    variables: { cartId, itemId },
    errorPolicy: 'all',
  });

const mergeCarts = (sourceCartId: string, destinationCartId: string) =>
  gqlClient.mutate<MergeCart, MergeCartVariables>({
    mutation: CART_MERGE,
    variables: {
      sourceCartId,
      destinationCartId,
    },
    errorPolicy: 'all',
  });

const setEmail = (input: SetGuestEmailOnCartInput) =>
  gqlClient.mutate<SetEmailOnCart, SetEmailOnCartVariables>({
    mutation: CART_SET_EMAIL,
    variables: {
      input,
    },
    errorPolicy: 'all',
  });

const setBillingAddress = (input: SetBillingAddressOnCartInput) =>
  gqlClient.mutate<SetBillingAddress, SetBillingAddressVariables>({
    mutation: CART_SET_BILLINGADDRESS,
    variables: {
      input,
    },
    errorPolicy: 'all',
  });

const setShippingAddresses = (input: SetShippingAddressesOnCartInput) =>
  gqlClient.mutate<
    SetShippingAddressesOnCart,
    SetShippingAddressesOnCartVariables
  >({
    mutation: CART_SET_SHIPPINGADDRESSES,
    variables: {
      input,
    },
    errorPolicy: 'all',
  });

const setShippingMethods = (input: SetShippingMethodsOnCartInput) =>
  gqlClient.mutate<SetShippingMethodsOnCart, SetShippingMethodsOnCartVariables>(
    {
      mutation: CART_SET_SHIPPINGMETHODS,
      variables: {
        input,
      },
      errorPolicy: 'all',
    }
  );

const setPaymentMethod = (input: SetPaymentMethodOnCartInput) =>
  gqlClient.mutate<SetPaymentMethodOnCart, SetPaymentMethodOnCartVariables>({
    mutation: CART_SET_PAYMENTMETHOD,
    variables: {
      input,
    },
    errorPolicy: 'all',
  });

const createPaypalExpressToken = (input: PaypalExpressTokenInput) =>
  gqlClient.mutate<CreatePaypayExpressToken, CreatePaypayExpressTokenVariables>(
    {
      mutation: CART_CREATE_PAYPALEXPRESSTOKEN,
      variables: {
        input,
      },
    }
  );

const placeOrder = (input: PlaceOrderInput) =>
  gqlClient.mutate<PlaceOrder, PlaceOrderVariables>({
    mutation: PLACE_ORDER,
    variables: {
      input,
    },
    errorPolicy: 'all',
  });

const getCheckoutagreements = () =>
  gqlClient.query<CheckoutAgreements>({
    query: CART_GET_CHECKOUTAGREEMENTS,
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
  });

export const cartApi = {
  mergeCarts,
  removeItem,
  updateItem,
  addItem,
  getCustomerCart,
  getCart,
  createCart,
  createPaypalExpressToken,
  setEmail,
  setBillingAddress,
  setShippingAddresses,
  setShippingMethods,
  setPaymentMethod,
  placeOrder,
  getCheckoutagreements,
};
