import React, { useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';

type ButtonStyle = 'primary' | 'secondary' | 'white' | 'bordered' | 'plain';
type ButtonSize = 1 | 2 | null;

export type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'style'
> & {
  loading?: boolean;
  size: ButtonSize;
  variant: ButtonStyle;
  confirm?: string;
};

const classesForVariant = (style?: ButtonStyle) => {
  switch (style) {
    case 'primary':
      return 'bg-blue-1 text-white-1 font-semibold';
    case 'secondary':
      return 'bg-blue-3 text-blue-1 font-normal';
    case 'white':
      return 'bg-white-1 font-semibold';
    case 'bordered':
      return 'bg-white-1 font-semibold border-default';
    default:
      return '';
  }
};
const classesForSize = (size?: ButtonSize) => {
  switch (size) {
    case 1:
      return 'text-15 px-6 py-4';
    case 2:
      return 'text-13 px-4 py-3';
  }
};

export function Button({
  className,
  variant,
  size,
  loading,
  children,
  onClick,
  confirm,
  ...rest
}: ButtonProps) {
  const [clicked, setClicked] = useState<boolean>(false);
  return (
    <button
      className={clsx(
        className,
        'rounded-lg',
        'relative transition-opacity duration-300',
        classesForSize(size),
        classesForVariant(variant),
        rest.disabled ? 'opacity-50' : 'opacity-100'
      )}
      onClick={e => {
        if (clicked) return;
        if (confirm) {
          e.preventDefault();
          setClicked(true);
        } else {
          onClick?.(e);
        }
      }}
      {...rest}>
      {loading && (
        <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
          <FontAwesomeIcon className="text-2xl" icon={faSpinner} spin />
        </div>
      )}

      <span className={clsx(loading && 'invisible', 'whitespace-nowrap')}>
        {children}
      </span>

      {confirm && clicked && (
        <div
          className={clsx(
            'rounded-lg',
            'border-default',
            'absolute z-30 top-0 left-0 right-0 -m-4 flex flex-row flex-wrap',
            classesForSize(size),
            classesForVariant(variant)
          )}>
          <span className="w-full border-b-default border-gray-1 pb-2">
            {confirm}
          </span>

          <span
            className="w-1/2 p-2 border-r-default border-b-default border-l-default"
            onClick={e => {
              setClicked(false);
              onClick?.(e as any);
            }}>
            Ja
          </span>
          <span
            className="w-1/2 p-2 border-r-default border-b-default"
            onClick={() => {
              setClicked(false);
            }}>
            Nein
          </span>
        </div>
      )}
    </button>
  );
}
