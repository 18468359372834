import React, { ReactNode, useEffect, useState } from 'react';

import clsx from 'clsx';
import { Button } from '../button/Button';
import { AmountInput } from './AmountInput';

export function AddToCartButton({
  label = 'In den Warenkorb',
  showAmount,
  type,
  onAdd,
  disabled,
  loading,
  minAmount,
}: {
  label?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  showAmount?: boolean;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  onAdd?: (quantity: number) => void;
  minAmount?: number;
}) {
  const [quantity, setQuantity] = useState(minAmount || 1);
  const handleAddToCart = e => {
    onAdd?.(quantity);
  };

  useEffect(() => {
    if (minAmount && quantity < minAmount) {
      setQuantity(minAmount);
    }
  }, [minAmount]);

  return (
    <div className="flex flex-col gap-2">
      <label className={clsx('text-15 font-semibold', !showAmount && 'hidden')}>
        Menge
      </label>
      <div className="flex flex-row gap-4">
        {showAmount && (
          <AmountInput
            min={minAmount}
            quantity={quantity}
            onChange={amount => setQuantity(amount)}
          />
        )}

        <Button
          disabled={disabled || loading}
          loading={loading}
          type={type}
          onClick={handleAddToCart}
          variant="primary"
          size={2}
          className="flex-1">
          {label}
        </Button>
      </div>
    </div>
  );
}
