import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from '@reach/router';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export function EcoHosting() {
  return (
    <AnchorLink to="/unternehmen#umweltschutz">
      <StaticImage
        onClick={() => navigate('/unternehmen/#umweltschutz')}
        src="../../../assets/image/Ökostrom Hosting.png"
        alt="hosting powered by renewable energy"
        layout="fixed"
        placeholder="blurred"
        width={140}
        height={140}
        loading="lazy"
      />
    </AnchorLink>
  );
}
