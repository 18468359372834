import {
  GetCart_cart_shipping_addresses,
  GetCart_cart_shipping_addresses_available_shipping_methods,
} from 'src/generated/api.types';

/**
 * Returns all available shipping methods from all available shipping addresses.
 * @param shippingAddresses
 * @returns
 */
export function getAvailableShippingMethods(
  shippingAddresses: GetCart_cart_shipping_addresses[] = []
) {
  const availableMethodsMap = shippingAddresses
    .map(adr => adr.available_shipping_methods)
    .flat()
    .reduce<
      Record<string, GetCart_cart_shipping_addresses_available_shipping_methods>
    >(
      (methodsMap, method) => ({
        ...methodsMap,
        [method.method_code]: method,
      }),
      {}
    );

  return Object.values(availableMethodsMap);
}
