const formats: Map<string, Intl.NumberFormat> = new Map();
export function getCurrencyFormat(currency: string) {
  if (!formats.has(currency)) {
    formats.set(
      currency,
      new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency,
      })
    );
  }

  return formats.get(currency);
}

export function getNumberFormat(locale: string = 'de-DE') {
  if (!formats.has(locale)) {
    formats.set(locale, new Intl.NumberFormat('de-DE', {}));
  }

  return formats.get(locale);
}
