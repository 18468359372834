import { createMachine } from 'xstate/lib/Machine';
import { interpret } from 'xstate/lib/interpreter';
import { Typestate, EventObject } from 'xstate/lib/types';
import { assign, send, log } from 'xstate/lib/actions';

import {
  AddProductVariables,
  BillingAddressInput,
  CartItemUpdateInput,
  GetCart,
  PlaceOrder_placeOrder_order,
  SetPaymentMethodOnCartInput,
  SetShippingAddressesOnCartInput,
  SetShippingMethodsOnCartInput,
  CustomizableOptionInput,
} from 'src/generated/api.types';
import { storage } from '@sus-core/utils/window';

import { authService } from '../auth/auth.machine';
import { choose } from 'xstate/lib/actions';
import { cartApi } from '@sus-core/api/service/cart';
import { ApolloError } from '@apollo/client';
import { containsEntityErrors } from '@sus-core/api/client/apiErrors';
import { resolveWhen } from '../../utils/resolveWhen';
import { sequence } from '../../../utils/sequence';
import { notifyError } from '@sus-core/utils/notify';
import { GraphQLErrors } from '@apollo/client/errors';
import { getSelectedShippingMethod } from './getSelectedShippingMethod';
import { getAvailableShippingMethods } from './getAvailableShippingMethods';

type CartContent = GetCart['cart'];
type AddProduct = Omit<AddProductVariables, 'cartId'>;

export interface CartContext {
  guestCartId?: string;
  content?: CartContent;
  cartId?: string;
  auth?: any;
  productToAdd?: AddProduct | null;
  productToRemoveId?: number | null;
}

export enum CART_EVENT_TYPES {
  ADD_ITEM = 'ADD_ITEM',
  REMOVE_ITEM = 'REMOVE_ITEM',
  UPDATE_ITEM = 'UPDATE_ITEM',
  AUTH_CHANGE = 'AUTH_CHANGE',
  SET_ADDRESSES = 'SET_ADDRESSES',
  SET_SHIPPINGMETHOD = 'SET_SHIPPINGMETHOD',
  SET_PAYMENTMETHOD = 'SET_PAYMENTMETHOD',
  PLACE_ORDER = 'PLACE_ORDER',
  PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS',
  RESUME = 'RESUME',

  //
  CART_ADDRESSES_UPDATE_DONE = 'CART_ADDRESSES_UPDATE_DONE',
}

export const GUEST_CART_KEY = 'sus_cart_id';

interface AddCartItemEvent {
  type: CART_EVENT_TYPES.ADD_ITEM;
  data: Omit<AddProductVariables, 'cartId'>;
}
interface RemoveCartItemEvent {
  type: CART_EVENT_TYPES.REMOVE_ITEM;
  data: { itemId: number };
}
interface UpdateCartItemEvent {
  type: CART_EVENT_TYPES.UPDATE_ITEM;
  data: CartItemUpdateInput[];
}
interface PlaceOrderEvent {
  type: CART_EVENT_TYPES.PLACE_ORDER;
  data: any;
}

interface PlaceOrderSuccessEvent {
  type: CART_EVENT_TYPES.PLACE_ORDER_SUCCESS;
  data: PlaceOrder_placeOrder_order;
}

interface ApiErrorEvent {
  type: 'API_ERROR';
  data: ApolloError;
}
// interface ResumeEvent {
//   type: CART_EVENT_TYPES.RESUME;
//   data?: any;
// }

export type SetCartAddressesParams = {
  shipping?: SetShippingAddressesOnCartInput['shipping_addresses'];
  billing?: BillingAddressInput;
  email?: string;
};

interface SetCartAddressesEvent {
  type: CART_EVENT_TYPES.SET_ADDRESSES;
  data: SetCartAddressesParams;
}
interface SetCartShippingMethodEvent {
  type: CART_EVENT_TYPES.SET_SHIPPINGMETHOD;
  data: SetShippingMethodsOnCartInput['shipping_methods'];
}
interface SetCartPaymentMethodEvent {
  type: CART_EVENT_TYPES.SET_PAYMENTMETHOD;
  data: SetPaymentMethodOnCartInput['payment_method'];
}

interface CartDataEvent extends EventObject {
  data: CartContent;
}

export type CartEvent =
  | AddCartItemEvent
  | RemoveCartItemEvent
  | UpdateCartItemEvent
  | ApiErrorEvent
  | SetCartAddressesEvent
  | SetCartShippingMethodEvent
  | SetCartPaymentMethodEvent
  | PlaceOrderEvent
  | PlaceOrderSuccessEvent
  | { type: CART_EVENT_TYPES.AUTH_CHANGE; data: any };

export interface CartStateSchema {
  states: {
    idle: any; // default
    creating: any; // create a new cart (guest or customer)
    merging: any; // after signing in; merge guest cart and customer cart
    loading: any; // loading a cart (guest or customer),
    unload: any;
    adding: any; // add item to cart
    updating: any; // update item in cart
    removing: any; // remove item from cart,
    updateAddress: any;
    updateShipping: any;
    updatePayment: any;
    placeOrder: any;
    error: any;
  };
}

const isGuest = () => authService.state.matches('unauthorized');

const isLoggedIn = () => authService.state.matches('authorized');

const hasCart = (context: CartContext) =>
  isLoggedIn() ? !!context.cartId : !!context.guestCartId;

const hasNoCart = (context: CartContext) =>
  isLoggedIn() ? !context.cartId : !context.guestCartId;

const hasEntityErrors = (context, e) =>
  containsEntityErrors((e as ApiErrorEvent).data);

const needsToLoadCart = context => {
  return isGuest()
    ? context.guestCartId && context.guestCartId !== context.content?.id
    : !context.cartId || context.content?.id !== context.cartId;
};

// const debugQueryErrors = (result: ApolloQueryResult<any>) => {
//   if (result.error) {
//     notifyWarning(`Result error: ${result.error.message}`);
//   }

//   if (result.errors) {
//     result.errors.forEach((error, idx) => {
//       notifyWarning(
//         `Result errors[${idx}]: ${error.message} | ${error['debugMessage']}`
//       );
//     });
//   }

//   return result;
// };

const cartMachine = createMachine<
  CartContext,
  CartEvent,
  Typestate<CartContext>
>(
  {
    id: 'cart',
    context: {
      guestCartId: storage.getItem(GUEST_CART_KEY),
    },
    initial: 'idle',
    on: {
      AUTH_CHANGE: [
        /*
        unauth => auth
        if no guest cart
        => 'idle'

        if guest cart
        => load customer cart
        => create customer cart
        => merge carts

        auth => unauth
        if cartId
        => remove cartId and contents
        
        */
        { target: 'idle' },
      ],
      [CART_EVENT_TYPES.PLACE_ORDER_SUCCESS]: {
        actions: [
          // (ctx, event) => console.log('placed order success:', event.data, ctx),
        ],
      },
    },
    invoke: {
      id: 'cartManager',
      src: (context, event) => (cb, onReceive) => {
        onReceive(event => {
          if (event.type === CART_EVENT_TYPES.CART_ADDRESSES_UPDATE_DONE) {
            const { data } = event as CartDataEvent;

            // const previousSelectedMethod = getSelectedShippingMethod(
            //   context.content.shipping_addresses
            // );

            const availableShippingMethods = getAvailableShippingMethods(
              data.shipping_addresses
            );

            const selectedShippingMethod = getSelectedShippingMethod(
              data.shipping_addresses
            );

            console.log(
              'avaliable shipping methods:',
              availableShippingMethods
            );
            console.log('selected shipping method:', selectedShippingMethod);

            const onlyOneShippingOption = availableShippingMethods.length === 1;
            const hasNotSelectedShipping = !selectedShippingMethod;
            // const hasChangedSelection =
            //   selectedShippingMethod?.method_code !==
            //   previousSelectedMethod?.method_code;

            if (hasNotSelectedShipping && onlyOneShippingOption) {
              const method = availableShippingMethods[0];
              const newShippingMethodEvent: SetCartShippingMethodEvent = {
                type: CART_EVENT_TYPES.SET_SHIPPINGMETHOD,
                data: [
                  {
                    carrier_code: method.carrier_code,
                    method_code: method.method_code,
                  },
                ],
              };

              cb(newShippingMethodEvent);
            }
          }
        });
      },
    },
    states: {
      idle: {
        always: [
          {
            cond: 'needsToLoadCart',
            target: 'loading',
          },
          {
            cond: context =>
              isLoggedIn() && !!context.guestCartId && !!context.cartId,
            target: 'merging',
          },
          {
            cond: context =>
              isGuest() && !context.guestCartId && !!context.content,
            target: 'unload',
          },
        ],
        on: {
          [CART_EVENT_TYPES.ADD_ITEM]: [
            {
              cond: 'hasCart',
              target: 'adding',
            },
            {
              cond: 'hasNoCart',
              target: 'creating',
            },
          ],
          [CART_EVENT_TYPES.REMOVE_ITEM]: 'removing',
          [CART_EVENT_TYPES.UPDATE_ITEM]: 'updating',
          [CART_EVENT_TYPES.SET_ADDRESSES]: 'updateAddresses',
          [CART_EVENT_TYPES.SET_SHIPPINGMETHOD]: 'updateShipping',
          [CART_EVENT_TYPES.SET_PAYMENTMETHOD]: 'updatePayment',
          [CART_EVENT_TYPES.PLACE_ORDER]: 'placeOrder',
        },
      },
      creating: {
        entry: assign({
          productToAdd: (ctx, event) => event.data,
        }),
        invoke: {
          id: 'create-cart',
          src: 'createCart',
          onDone: [
            {
              actions: choose([
                {
                  cond: 'isGuest',
                  actions: assign({
                    guestCartId: (context, event) => event.data,
                  }),
                },
                {
                  cond: 'isLoggedIn',
                  actions: assign({
                    cartId: (context, event) => event.data,
                  }),
                },
              ]),
              target: 'adding',
            },
          ],
          onError: 'idle',
        },
      },
      merging: {
        invoke: {
          id: 'merge-carts',
          src: 'mergeCarts',
          onDone: {
            target: 'idle',
            actions: [
              assign<Partial<CartContext>, CartDataEvent>({
                content: (context, event) => event.data ?? context.content,
              }),
              assign<Partial<CartContext>, CartDataEvent>({
                cartId: (context, event) => event.data?.id ?? context.cartId,
              }),
              assign<Partial<CartContext>>({
                guestCartId: () => null,
              }),
              'clearStorage',
            ],
          },
        },
      },
      error: {
        always: {
          target: 'unload',
          actions: 'clearStorage',
        },
      },
      loading: {
        invoke: {
          id: 'get-cart',
          src: 'getCart',
          onDone: {
            target: 'idle',
            actions: [
              assign({
                content: (context, event) => event.data,
              }),
              choose([
                {
                  cond: 'isLoggedIn',
                  actions: assign({
                    cartId: (ctx, event) => (event as any).data.id,
                  }),
                },
              ]),
            ],
          },
          onError: {
            target: 'error',
            actions: choose([
              {
                cond: 'hasEntityErrors',
                actions: [
                  assign({
                    guestCartId: (context, event) => null,
                  }),
                  'clearStorage',
                ],
              },
            ]),
          },
        },
      },
      unload: {
        entry: assign<Partial<CartContext>>(() => ({
          content: null,
          cartId: null,
          guestCartId: null,
          productToAdd: null,
          productToRemoveId: null,
        })),
        always: [{ target: 'idle' }],
      },
      adding: {
        entry: assign({
          productToAdd: (ctx, event) =>
            event.data?.sku ? event.data : ctx.productToAdd,
        }),
        invoke: {
          id: 'add-cart-item',
          src: 'addItem',
          onDone: {
            target: 'idle',
            actions: [
              assign<Partial<CartContext>, CartDataEvent>({
                content: (context, event) =>
                  (event.data ?? context.content) as CartContent,
              }),
              assign<Partial<CartContext>>({
                productToAdd: () => null,
              }),
            ],
          },
          onError: {
            target: 'idle',
            actions: [
              (ctx, event) => {
                console.error(event);
              },
              assign<Partial<CartContext>>({
                productToAdd: () => null,
              }),
            ],
          },
        },
      },
      updating: {
        invoke: {
          id: 'update-cart-item',
          src: 'updateItem',
          onDone: {
            target: 'idle',
            actions: assign({
              content: (context, event) => event.data ?? context.content,
            }),
          },
          onError: 'idle',
        },
      },
      removing: {
        entry: assign({
          productToRemoveId: (context, event) => event.data.itemId,
        }),
        invoke: {
          id: 'remove-cart-item',
          src: 'removeItem',

          onDone: {
            target: 'idle',
            actions: assign<Partial<CartContext>, CartDataEvent>({
              content: (context, event) => event.data ?? context.content,
              productToRemoveId: () => null,
            }),
          },
          onError: {
            target: 'idle',
            actions: [
              assign<Partial<CartContext>>({
                productToRemoveId: () => null,
              }),
            ],
          },
        },
      },
      updateAddresses: {
        invoke: {
          id: 'update-cart-addresses',
          src: 'setAddresses',
          onDone: {
            target: 'idle',
            actions: [
              send<Partial<CartContext>, CartDataEvent>(
                (context, event) => ({
                  type: CART_EVENT_TYPES.CART_ADDRESSES_UPDATE_DONE,
                  data: event.data,
                }),
                { to: 'cartManager' }
              ),
              assign<Partial<CartContext>, CartDataEvent>({
                content: (context, event) => {
                  return event.data ?? context.content;
                },
              }),
            ],
          },
          onError: {
            target: 'idle',
            actions: log(
              (context, event) =>
                `error setting shipping address : ${event.type}`,
              '[ERROR]'
            ),
          },
        },
      },
      updateShipping: {
        invoke: {
          id: 'update-shipping-method',
          src: 'setShippingMethod',
          onDone: {
            target: 'idle',
            actions: assign<Partial<CartContext>, CartDataEvent>({
              content: (context, event) => {
                return event.data ?? context.content;
              },
            }),
          },
          onError: {
            target: 'idle',
            actions: log(
              (context, event) =>
                `error setting shipping method: ${event.type}`,
              '[ERROR]'
            ),
          },
        },
      },
      updatePayment: {
        invoke: {
          id: 'update-payment-method',
          src: 'setPaymentMethod',
          onDone: {
            target: 'idle',
            actions: assign<Partial<CartContext>, CartDataEvent>({
              content: (context, event) => {
                return event.data ?? context.content;
              },
            }),
          },
          onError: {
            target: 'idle',
            actions: log(
              (context, event) => `error setting payment method: ${event.type}`,
              '[ERROR]'
            ),
          },
        },
      },
      placeOrder: {
        invoke: {
          id: 'place-order',
          src: 'placeOrder',
          onDone: {
            target: 'unload',
            actions: [
              send((ctx, event) => ({
                type: CART_EVENT_TYPES.PLACE_ORDER_SUCCESS,
                data: event.data,
              })),
              'clearStorage',
            ],
          },
        },
      },
    },
  },
  {
    guards: {
      isGuest,
      isLoggedIn,
      hasCart,
      hasNoCart,
      needsToLoadCart,
      hasEntityErrors,
    },
    actions: {
      clearStorage: () => storage.removeItem(GUEST_CART_KEY),
    },
    services: {
      createCart: () =>
        cartApi
          .createCart()
          .then(result => result.data.createEmptyCart)
          .then(cartId => {
            storage.setItem(GUEST_CART_KEY, cartId);
            return cartId;
          }),
      getCart: context =>
        isLoggedIn()
          ? cartApi
              .getCustomerCart()
              // .then(debugQueryErrors)
              .then(result => {
                if (!result.data?.customerCart && result.errors) {
                  // alert('ERRORs' + JSON.stringify(result.errors, null, 2));
                  throw 'CART_LOAD_ERROR';
                }
                return result.data?.customerCart;
              })
          : cartApi
              .getCart(context.guestCartId)
              // .then(debugQueryErrors)
              .then(result => {
                if (!result.data?.cart && result.errors) {
                  // alert('ERRORs' + JSON.stringify(result.errors, null, 2));
                  throw 'CART_LOAD_ERROR';
                }
                return result.data?.cart;
              }),
      addItem: (context, event) => {
        const product = context.productToAdd || (event.data as AddProduct);
        product.options = product.options?.reduce<CustomizableOptionInput[]>(
          (result, current) => {
            // merge all option values with the same id => values to array
            const member = result.find(i => i.id === current.id);
            if (!member) return [...result, current];

            if (member.value_string.includes(current.value_string)) {
              return result;
            }

            member.value_string = `[${member.value_string
              .replace('[', '')
              .replace(']', '')},${current.value_string}]`;

            return result;
          },
          []
        );

        return cartApi
          .addItem({
            cartId: context.content?.id
              ? context.content?.id
              : isLoggedIn()
              ? context.cartId
              : context.guestCartId,
            ...product,
          })
          .then(result => {
            const errors = result?.errors;
            filterErrors(errors)?.forEach(err => notifyError(err.message));
            return result.data?.addSimpleProductsToCart?.cart;
          });
      },
      updateItem: (context, event) => {
        const items = (event as UpdateCartItemEvent).data;
        return cartApi
          .updateItem({ cart_id: context.content.id, cart_items: items })
          .then(result => result.data?.updateCartItems?.cart);
      },
      removeItem: (context, event) =>
        cartApi
          .removeItem(
            context.content.id,
            (event as RemoveCartItemEvent).data.itemId
          )
          .then(result => result.data?.removeItemFromCart?.cart),
      mergeCarts: context =>
        cartApi
          .mergeCarts(context.guestCartId, context.cartId)
          .then(result => result.data?.mergeCarts),
      setAddresses: (context, event) => {
        const e = event as SetCartAddressesEvent;
        const cart_id = isLoggedIn() ? context.cartId : context.guestCartId;

        const tasks: [
          (() => Promise<CartContent>) | null,
          (() => Promise<CartContent>) | null,
          (() => Promise<CartContent>) | null
        ] = [null, null, null];

        // make request sequential to prevent race conditions

        if (!isLoggedIn() && e.data.email) {
          tasks[0] = () =>
            cartApi
              .setEmail({ cart_id, email: e.data.email })
              .then(result => result.data.setGuestEmailOnCart.cart);
        }

        if (e.data.billing) {
          tasks[1] = () =>
            cartApi
              .setBillingAddress({
                cart_id,
                billing_address: (event as SetCartAddressesEvent).data.billing,
              })
              .then(result => result.data.setBillingAddressOnCart.cart);
        }

        if (e.data.shipping) {
          tasks[2] = () =>
            cartApi
              .setShippingAddresses({
                cart_id,
                shipping_addresses: (event as SetCartAddressesEvent).data
                  .shipping,
              })
              .then(result => result.data.setShippingAddressesOnCart.cart);
        }

        return sequence(tasks).then(_results => {
          // remove void results
          // since all queries return a cart object we only need the last returned value
          const results = _results.filter(_ => _);
          return results[results.length - 1];
        });
      },
      setShippingMethod: (context, e) => {
        const event = e as SetCartShippingMethodEvent;
        const cart_id = isLoggedIn() ? context.cartId : context.guestCartId;
        return cartApi
          .setShippingMethods({ cart_id, shipping_methods: event.data })
          .then(result => result.data.setShippingMethodsOnCart.cart);
      },
      setPaymentMethod: (context, e) => {
        const event = e as SetCartPaymentMethodEvent;
        const cart_id = isLoggedIn() ? context.cartId : context.guestCartId;
        return cartApi
          .setPaymentMethod({ cart_id, payment_method: event.data })
          .then(result => result.data.setPaymentMethodOnCart.cart);
      },
      placeOrder: (context, e) => {
        const cart_id = isLoggedIn() ? context.cartId : context.guestCartId;
        return cartApi
          .placeOrder({ cart_id })
          .then(result => result.data.placeOrder.order);
      },
    },
  }
);

export const cartService = interpret(cartMachine)
  // .onTransition(state => console.log('cart state:', state.value))
  .start();

export interface OptionParameter {
  id: number;
  value_string: string;
}

export type AddOperation = (
  sku: string,
  quantity: number,
  options?: OptionParameter[]
) => void;
// Cart event factories / Cart actions
export const updateCartItems = (data: CartItemUpdateInput[]) => {
  const event: UpdateCartItemEvent = {
    type: CART_EVENT_TYPES.UPDATE_ITEM,
    data,
  };
  cartService.send(event as any);

  return resolveWhen({
    doneEventType: 'done.invoke.update-cart-item',
    service: cartService,
  });
};

export const addCartItem = (params: AddCartItemEvent['data']) => {
  const event: AddCartItemEvent = {
    type: CART_EVENT_TYPES.ADD_ITEM,
    data: params,
  };
  cartService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.add-cart-item',
    service: cartService,
  });
};

export const removeCartItem = (itemId: number) => {
  const event: RemoveCartItemEvent = {
    type: CART_EVENT_TYPES.REMOVE_ITEM,
    data: { itemId },
  };
  cartService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.remove-cart-item',
    service: cartService,
  });
};

export const setCartAddresses = (
  data: SetCartAddressesParams
): Promise<void> => {
  const event: SetCartAddressesEvent = {
    type: CART_EVENT_TYPES.SET_ADDRESSES,
    data,
  };

  cartService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.update-cart-addresses',
    service: cartService,
  });
};

export const setCartShippingMethod = (
  data: SetCartShippingMethodEvent['data']
) => {
  const event: SetCartShippingMethodEvent = {
    type: CART_EVENT_TYPES.SET_SHIPPINGMETHOD,
    data,
  };

  cartService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.update-shipping-method',
    service: cartService,
  });
};

export const setCartPaymentMethod = (
  data: SetCartPaymentMethodEvent['data']
) => {
  const event: SetCartPaymentMethodEvent = {
    type: CART_EVENT_TYPES.SET_PAYMENTMETHOD,
    data,
  };

  cartService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.update-payment-method',
    service: cartService,
  });
};

export const placeOrderOnCart = () => {
  const event: PlaceOrderEvent = {
    type: CART_EVENT_TYPES.PLACE_ORDER,
    data: undefined,
  };

  cartService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.place-order',
    service: cartService,
  });
};

//subscribe to auth state changes
authService.onTransition(state => {
  if (['authorized', 'unauthorized'].some(state.matches)) {
    cartService.send({ type: CART_EVENT_TYPES.AUTH_CHANGE, data: state.value });
  }
});

/**
 * Removes errors from error array:
 * - missing region code error
 *
 * @param errors
 * @returns
 */
function filterErrors(errors?: GraphQLErrors) {
  return errors?.filter(
    err =>
      err.path?.join() !==
      [
        'addSimpleProductsToCart',
        'cart',
        'billing_address',
        'region',
        'code',
      ].join()
  );
}
