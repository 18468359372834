import { FilterActor } from '@sus-core/state/xstate/app/app.machine';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';
import {
  Aggregations,
  AttributeMetaData,
  FilterItem,
} from '@sus-core/state/xstate/filter/filter.machine';

import React, { useEffect, useState } from 'react';
import { FilterProps, Filters } from '../filter/Filters';

// @TODO move this to appropriate file
export function getFilterInputParams(
  meta: AttributeMetaData = [],
  filterValues: Record<string, any>,
  categoryId?: string
) {
  const matchingMeta = meta.filter(m => !!filterValues[m.attribute_code]);
  const params = matchingMeta
    .map(attributeMeta => {
      switch (attributeMeta.input_type) {
        case 'multiselect':
        case 'select':
        case 'boolean':
          const values = filterValues[attributeMeta.attribute_code];
          if (Array.isArray(values)) {
            return values.length > 1
              ? {
                  [attributeMeta.attribute_code]: {
                    in: values.join(','),
                  },
                }
              : {
                  [attributeMeta.attribute_code]: {
                    eq: values[0],
                  },
                };
          }
          // eq or in if multiple selected
          return {
            [attributeMeta.attribute_code]: {
              eq: filterValues[attributeMeta.attribute_code],
            },
          };

        case 'price':
          let [from, to] =
            filterValues[attributeMeta.attribute_code]?.split('_');

          if (from === '*') from = undefined;
          if (to === '*') to = undefined;

          return {
            [attributeMeta.attribute_code]: { from, to },
          };
        default:
          return undefined;
      }
    })
    .reduce((map, item) => {
      return { ...map, ...item };
    }, {});

  if (categoryId) {
    params['category_id'] = { eq: categoryId };
  }

  return params;
}

export type ProductFilterProps = {
  filterId: string | number;
  className?: string;
  loading?: boolean;
  onChange: (
    filterValues: Record<string, number | string | (number | string)[]>,
    updateAggregations: (aggregations: Aggregations) => void,
    meta?: AttributeMetaData
  ) => void;
  aggregations?: Aggregations;
  meta?: AttributeMetaData;
};

export function findOption(
  aggregations: Aggregations,
  attributeCode: string,
  value: any
) {
  return aggregations
    ?.find(agg => agg.attribute_code === attributeCode)
    ?.options.find(opt => opt.value === value);
}

export function shouldBeMulti(
  meta?: AttributeMetaData[0]
): FilterItem['multiSelect'] {
  switch (meta?.input_type) {
    case 'multiselect':
    case 'select':
    case 'boolean':
      return true;
    case 'price':
      return false;

    default:
      return true;
  }
}

export function ProductFilters({
  filterId,
  className,
  onChange,
  loading,
  aggregations,
  meta,
}: ProductFilterProps) {
  if (process.env.GATSBY_FEATURE_FILTERS !== 'true') {
    return null;
  }

  const { getFilters } = useAppService();

  const [filterActor, setFilterActor] = useState<FilterActor>();

  useEffect(() => {
    if (filterId && aggregations && meta) {
      const actor = getFilters(filterId, aggregations, meta);
      setFilterActor(actor);
    }
  }, [filterId, aggregations, meta]);

  const handleChange: FilterProps['onChange'] = values => {
    filterActor.send({ type: 'FILTER_CHANGE', values });
    onChange?.(values, newAggregations => {
      filterActor.send({
        type: 'FILTER_AGGREGATION_UPDATE',
        aggregations: newAggregations,
      });
    });
  };

  return (
    <Filters
      items={filterActor?.getSnapshot().context.items}
      loading={loading}
      className={className}
      onChange={handleChange}
    />
  );
}
