import React from 'react';

export type ContentSectionProps = {
  content?: string;
  className?: string;
  heading?: string;
};

// const splitByHeadings = (content: string) =>
//   content.split(/(<h\d>.*<\/h\d>)/).filter(_ => _);

// const isHtmlHeading = (str: string) => str.startsWith('<h');
// const extractTagContents = (str: string) => {
//   const match = str.match(/^(<h\d>)(.*?)(<\/h\d>$)/i);
//   return match[2] || str;
// };

// type ContentSections = Array<{ heading?: string; contents: string }>;
// const createSections = (contents: string[]) =>
//   contents.reduce<ContentSections>((result, item) => {
//     const currentSection = result[result.length - 1];
//     const isHeading = isHtmlHeading(item);

//     if (isHeading) {
//       return [...result, { heading: extractTagContents(item), contents: '' }];
//     } else if (currentSection) {
//       currentSection.contents += item;
//     } else {
//       return [...result, { contents: item }];
//     }

//     return result;
//   }, []);

export function ContentSection({
  content,
  className,
  heading,
}: ContentSectionProps) {
  return (
    <section className={className}>
      {heading && <h1 className="text-center mb-16">{heading}</h1>}
      {
        content && <div dangerouslySetInnerHTML={{ __html: content }} />

        /**
         * tmp disable collapsible section creation and display content as provided from cms
         */

        // createSections(splitByHeadings(content)).map((item, idx) => {
        //   if (item.heading) {
        //     return (
        //       <Collapsible
        //         key={idx}
        //         title={item.heading}
        //         content={makeLinksRelative(item.contents, location.origin)}
        //       />
        //     );
        //   }
        //   return (
        //     <div
        //       key={idx}
        //       dangerouslySetInnerHTML={{
        //         __html: makeLinksRelative(item.contents, location.origin),
        //       }}
        //     />
        //   );
        // })
      }
    </section>
  );
}
