import { SEARCH_PRODUCTS, FILTER_PRODUCTS } from '..';
import {
  FilterProducts,
  FilterProductsVariables,
  ProductAttributeFilterInput,
  SearchProducts,
  SearchProductsVariables,
} from '../../../generated/api.types';

import { gqlClient } from '../client/sus-graphql-client';

export const searchProducts = (
  search: string,
  filter?: ProductAttributeFilterInput,
  currentPage?: number
) =>
  gqlClient.query<SearchProducts, SearchProductsVariables>({
    query: SEARCH_PRODUCTS,
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    variables: {
      search,
      pageSize: 50,
      filter,
      currentPage,
    },
  });

export const filterProducts = (filter?: ProductAttributeFilterInput) =>
  gqlClient.query<FilterProducts, FilterProductsVariables>({
    query: FILTER_PRODUCTS,
    errorPolicy: 'none',
    fetchPolicy: 'network-only',
    variables: {
      filter,
    },
  });
