import { interpret } from 'xstate/lib/interpreter';
import { assign } from 'xstate/lib/actions';
import { Machine } from 'xstate/lib/Machine';
import { customerApi } from '@sus-core/api/service/customer';

import {
  GetCustomer,
  CustomerInput,
  CreateCustomer,
  CustomerAddressInput,
  UpdateCustomerAddressVariables,
  DeleteCustomerAddressVariables,
} from 'src/generated/api.types';
import { authService } from '../auth/auth.machine';
import { log } from 'xstate/lib/actions';
import { resolveWhen } from '@sus-core/state/utils/resolveWhen';

export interface CustomerContext {
  customer?:
    | GetCustomer['customer']
    | CreateCustomer['createCustomer']['customer'];
  error?: any;
  deletingAddresses: number[];
  updatingAddresses: number[];
}

interface CustomerStateSchema {
  states: {
    pending: any;
    guest: any;
    customer: {
      states: {
        idle: any;
        creatingAddress: any;
        updatingAddress: any;
        deletingAddress: any;
        updatingCustomer: any;
      };
    };
    loading: any;
    creating: any;
  };
}

export enum CUSTOMER_EVENTS {
  CREATE_ADDRESS = 'CREATE_ADDRESS',
  UPDATE_ADDRESS = 'UPDATE_ADDRESS',
  DELETE_ADDRESS = 'DELETE_ADDRESS',
  CREATE_CUSTOMER = 'CREATE_CUSTOMER',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  RELOAD = 'RELOAD_CUSTOMER',
  RESET_ERROR = 'RESET_ERROR',
}

export interface CreateCustomerEvent {
  type: CUSTOMER_EVENTS.CREATE_CUSTOMER;
  data: CustomerInput;
}
export interface CreateCustomerAddressEvent {
  type: CUSTOMER_EVENTS.CREATE_ADDRESS;
  data: CustomerAddressInput;
}
export interface DeleteCustomerAddressEvent {
  type: CUSTOMER_EVENTS.DELETE_ADDRESS;
  data: DeleteCustomerAddressVariables;
}
export interface UpdateCustomerAddressEvent {
  type: CUSTOMER_EVENTS.UPDATE_ADDRESS;
  data: UpdateCustomerAddressVariables;
}

export interface UpdateCustomerEvent {
  type: CUSTOMER_EVENTS.UPDATE_CUSTOMER;
  data: CustomerInput;
}
export interface ReloadCustomerEvent {
  type: CUSTOMER_EVENTS.RELOAD;
}

interface ResetErrorEvent {
  type: CUSTOMER_EVENTS.RESET_ERROR;
  data: null;
}

export type CustomerEvent =
  | { type: 'AUTH_CHANGE' }
  | CreateCustomerEvent
  | CreateCustomerAddressEvent
  | UpdateCustomerAddressEvent
  | DeleteCustomerAddressEvent
  | UpdateCustomerEvent
  | ReloadCustomerEvent
  | ResetErrorEvent;

const context: CustomerContext = {
  deletingAddresses: [],
  updatingAddresses: [],
};

const customerMachine = Machine<
  CustomerContext,
  CustomerStateSchema,
  CustomerEvent
>(
  {
    id: 'customer',
    initial: 'pending',
    context,
    states: {
      pending: {
        always: [
          { cond: 'isAuthorized', target: 'loading' },
          { cond: 'isNotAuthorized', target: 'guest', actions: 'setCustomer' },
        ],
      },
      guest: {
        exit: ['clearError'],
      },
      customer: {
        initial: 'idle',
        exit: ['clearError'],
        states: {
          idle: {
            on: {
              [CUSTOMER_EVENTS.CREATE_ADDRESS]: 'creatingAddress',
              [CUSTOMER_EVENTS.UPDATE_ADDRESS]: 'updatingAddress',
              [CUSTOMER_EVENTS.DELETE_ADDRESS]: 'deletingAddress',
              [CUSTOMER_EVENTS.UPDATE_CUSTOMER]: 'updatingCustomer',
              [CUSTOMER_EVENTS.RELOAD]: '#customer.loading',
            },
          },
          creatingAddress: {
            invoke: {
              id: 'create-customer-address',
              src: 'createCustomerAddress',
              onDone: {
                target: '#customer.loading',
              },
              onError: {
                target: '#customer.loading',
              },
            },
          },
          updatingAddress: {
            entry: assign((ctx, event) => ({
              updatingAddresses: [
                ...ctx.updatingAddresses.filter(i => i !== undefined),
                (event as UpdateCustomerAddressEvent).data.id,
              ],
            })),
            exit: [
              assign((ctx, event) => ({
                updatingAddresses: [
                  ...ctx.updatingAddresses.filter(
                    i => i !== (event as any).data?.id
                  ),
                ],
              })),
            ],
            invoke: {
              id: 'update-customer-address',
              src: 'updateCustomerAddress',
              onDone: {
                target: '#customer.loading',
              },
              onError: {
                target: '#customer.loading',
              },
            },
          },
          deletingAddress: {
            entry: assign((ctx, event) => ({
              deletingAddresses: [
                ...ctx.deletingAddresses.filter(i => i !== undefined),
                (event as DeleteCustomerAddressEvent).data.id,
              ],
            })),
            exit: [
              assign((ctx, event) => ({
                deletingAddresses: [
                  ...ctx.deletingAddresses.filter(
                    i => i !== (event as any).data?.id
                  ),
                ],
              })),
            ],

            invoke: {
              id: 'delete-customer-address',
              src: 'deleteCustomerAddress',
              onDone: {
                target: '#customer.loading',
              },
              onError: {
                target: '#customer.loading',
              },
            },
          },
          updatingCustomer: {
            invoke: {
              id: 'update-customer',
              src: 'updateCustomer',
              onDone: {
                target: 'idle',
                actions: ['setCustomer'],
              },
              onError: {
                target: '#customer.loading',
              },
            },
          },
        },
      },
      loading: {
        invoke: {
          id: 'get-customer',
          src: 'getCustomer',
          onDone: {
            target: 'customer',
            actions: ['setCustomer'],
          },
          onError: {
            actions: [
              log(
                (ctx, event) => `error: ${JSON.stringify(event)}`,
                'CUSTOMER LOADING'
              ),
              () => authService.send('AUTH_ERROR'),
            ],
          },
        },
      },
      creating: {
        invoke: {
          id: 'create-customer',
          src: 'createCustomer',
          onDone: { target: 'pending', actions: ['setCustomer'] },
          onError: {
            target: 'pending',
            actions: assign({
              error: (ctx, event) => event.data,
            }),
          },
        },
      },
    },
    on: {
      AUTH_CHANGE: 'pending',
      [CUSTOMER_EVENTS.CREATE_CUSTOMER]: 'creating',
      [CUSTOMER_EVENTS.RESET_ERROR]: [
        {
          actions: ['clearError'],
        },
      ],
    },
  },
  {
    actions: {
      setCustomer: assign({
        customer: (ctx, event) => (event as any).data,
      }),
      clearError: assign((ctx, event) => ({
        error: undefined,
      })),
    },
    guards: {
      isAuthorized: () => authService.state.matches('authorized'),
      isNotAuthorized: () => authService.state.matches('unauthorized'),
    },
    services: {
      createCustomer: (ctx, event) =>
        customerApi
          .createCustomer((event as CreateCustomerEvent).data)
          .then(result => result.data.createCustomer.customer),
      createCustomerAddress: (ctx, event) =>
        customerApi.createCustomerAddress(
          (event as CreateCustomerAddressEvent).data
        ),
      updateCustomerAddress: (ctx, event) =>
        customerApi
          .updateCustomerAddress((event as UpdateCustomerAddressEvent).data)
          .then(result => ({
            result: result.data.updateCustomerAddress,
            id: (event as UpdateCustomerAddressEvent).data?.id,
          })),
      deleteCustomerAddress: (ctx, event) =>
        customerApi
          .deleteCustomerAddress((event as DeleteCustomerAddressEvent).data)
          .then(result => ({
            result: result,
            id: (event as DeleteCustomerAddressEvent).data?.id,
          })),
      getCustomer: () =>
        customerApi.getCustomer().then(result => {
          if (result.errors) {
            throw 'unauthorized';
          }

          return result.data?.customer;
        }),
      updateCustomer: (ctx, event) =>
        customerApi
          .updateCustomer((event as UpdateCustomerEvent).data)
          .then(result => {
            return result.data?.updateCustomer?.customer;
          }),
    },
  }
);

export const customerService = interpret(customerMachine)
  // .onTransition(state => console.log('customer state:', state.value))
  .start();

authService.onTransition(state => {
  customerService.send({
    type: 'AUTH_CHANGE',
  });
});

// event factories
export const createCustomer = (data: CustomerInput) => {
  customerService.send({
    type: CUSTOMER_EVENTS.CREATE_CUSTOMER,
    data,
  });

  return resolveWhen({
    doneEventType: 'done.invoke.create-customer',
    service: customerService,
  });
};

export const updateCustomer = (data: CustomerInput) => {
  customerService.send({
    type: CUSTOMER_EVENTS.UPDATE_CUSTOMER,
    data,
  });

  return resolveWhen({
    doneEventType: 'done.invoke.update-customer',
    service: customerService,
  });
};

export const createCustomerAddress = (data: CustomerAddressInput) => {
  const event: CreateCustomerAddressEvent = {
    type: CUSTOMER_EVENTS.CREATE_ADDRESS,
    data,
  };

  customerService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.create-customer-address',
    service: customerService,
  });
};
export const updateCustomerAddress = (data: UpdateCustomerAddressVariables) => {
  const event: UpdateCustomerAddressEvent = {
    type: CUSTOMER_EVENTS.UPDATE_ADDRESS,
    data,
  };
  customerService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.update-customer-address',
    service: customerService,
  });
};

export const deleteCustomerAddress = (data: DeleteCustomerAddressVariables) => {
  const event: DeleteCustomerAddressEvent = {
    type: CUSTOMER_EVENTS.DELETE_ADDRESS,
    data,
  };
  customerService.send(event);

  return resolveWhen({
    doneEventType: 'done.invoke.delete-customer-address',
    service: customerService,
  });
};

export const relaodCustomerData = () => {
  customerService.send({ type: CUSTOMER_EVENTS.RELOAD });
};

export const resetError = () => {
  customerService.send({ type: CUSTOMER_EVENTS.RESET_ERROR, data: null });
};
