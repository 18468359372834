import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isString from 'lodash/isString';

import { CustomerAddress } from '../address/AddressEditor';

const isNot = value =>
  isUndefined(value) || isNull(value) || (isString(value) && !value);

const compareValue = (a: any, b: any) => {
  //consider values equal if undefined, null or ""
  return (isNot(a) && isNot(b)) || a === b;
};

const streetValue = (val: string | string[] = []): string =>
  Array.isArray(val) ? val.join('') : val;

export const addressEquals = (a?: CustomerAddress, b?: CustomerAddress) => {
  return (
    compareValue(a?.city, b?.city) &&
    compareValue(a?.company, b?.company) &&
    compareValue(a?.country_code, b?.country_code) &&
    compareValue(a?.firstname, b?.firstname) &&
    compareValue(a?.lastname, b?.lastname) &&
    compareValue(a?.postcode, b?.postcode) &&
    // not using region => omit from check
    // a.region === b.region &&
    compareValue(streetValue(a?.street), streetValue(b?.street)) &&
    compareValue(a?.telephone, b?.telephone)
  );
};
