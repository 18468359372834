import React, { useMemo } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import { filterTree, Tree } from '@sus-core/utils/categories';

import { MobileNav } from './MobileNav';
import { DesktopNav } from './DesktopNav';
import { MEDIA_QUERY, useMediaQuery } from '@sus-core/hooks/useMediaQuery';

export function Navbar() {
  const data = useStaticQuery<GatsbyTypes.NavbarQuery>(graphql`
    query Navbar {
      sus {
        categoryList {
          id
          canonical_url
          include_in_menu
          level
          position
          name
          children {
            id
            canonical_url
            include_in_menu
            level
            position
            name
            children {
              id
              canonical_url
              include_in_menu
              level
              position
              name
              children {
                id
                canonical_url
                include_in_menu
                level
                position
                name
                children {
                  id
                  canonical_url
                  include_in_menu
                  level
                  position
                  name
                  children {
                    id
                    canonical_url
                    include_in_menu
                    level
                    position
                    name
                    children {
                      id
                      canonical_url
                      include_in_menu
                      level
                      position
                      name
                      children {
                        id
                        canonical_url
                        include_in_menu
                        level
                        position
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menuCategories = useMemo(
    () =>
      filterTree<GatsbyTypes.SUS_CategoryTree>(
        data.sus.categoryList[0] as Tree<GatsbyTypes.SUS_CategoryTree>,
        (item: GatsbyTypes.SUS_CategoryTree) => !!item.include_in_menu,
        (a, b) => a.position - b.position
      )?.children,
    [data.sus.categoryList]
  );

  const isMobile = useMediaQuery(MEDIA_QUERY.IS_MOBILE);

  if (isMobile) {
    return <MobileNav menuCategories={menuCategories} />;
  }

  return <DesktopNav menuCategories={menuCategories} />;
}
