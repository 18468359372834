import React from 'react';
import clsx from 'clsx';

import logoLg from '@assets/image/Logo-lg.svg';
import * as styles from './Logo.module.scss';

export function Logo({ className }: { className?: string }) {
  return (
    <img
      className={clsx(styles.root, className)}
      src={logoLg}
      alt="Schrank und Stuhl Logo"
    />
  );
}
