import { useStaticQuery, graphql } from 'gatsby';

export function useAllProductImages() {
  const { allMagentoProduct } =
    useStaticQuery<GatsbyTypes.AllProductImageQueryQuery>(
      graphql`
        query AllProductImageQuery {
          allMagentoProduct {
            edges {
              node {
                sku
                image {
                  childImageSharp {
                    gatsbyImageData(
                      height: 305
                      width: 508
                      transformOptions: { fit: CONTAIN }
                    )
                  }
                }
              }
            }
          }
        }
      `
    );
  return allMagentoProduct.edges;
}
