import React from 'react';
import clsx from 'clsx';
import { MoneyData, MoneyView } from './MoneyView';
import { graphql } from 'gatsby';

type PriceData = { final_price: MoneyData; regular_price: MoneyData };
export interface ProducPriceProps {
  price: PriceData;
  //  Pick<
  //   GatsbyTypes.MagentoProductPriceRangeMaximumPrice,
  //   'final_price' | 'discount' | 'regular_price'
  // >;
  adjustment: number;
  className?: string;
  isDiscount?: boolean;
}

export function ProductPrice({
  price,
  isDiscount,
  className,
  adjustment,
}: ProducPriceProps) {
  return (
    <div className={clsx('flex flex-col items-end', className)}>
      <MoneyView
        adjustment={adjustment}
        labelWith={isDiscount ? 'Regulärer Preis:' : undefined}
        classes={{
          labelWith: clsx('text-11', isDiscount && 'hidden'),
          labelWithout: clsx('text-11', isDiscount && 'hidden'),
          priceWithout: clsx(
            'text-primary text-17 md:text-28 font-regular',
            isDiscount && 'hidden'
          ),
          priceWith: clsx(
            'font-normal',
            isDiscount
              ? 'text-15 md:text-17 line-through'
              : 'text-primary text-15 md:text-17'
          ),
        }}
        price={price.regular_price}
      />

      {isDiscount && (
        <div className="text-danger text-15 md:text-17 font-semibold">
          Sonderpreis
        </div>
      )}

      {isDiscount && (
        <MoneyView
          adjustment={adjustment}
          classes={{
            labelWith: 'text-11',
            labelWithout: 'text-11',
            priceWithout: 'text-danger text-17 md:text-28 font-normal',
            priceWith: 'text-danger text-15 md:text-17 font-normal',
          }}
          price={price.final_price}
        />
      )}
    </div>
  );
}

export const priceFragment = graphql`
  fragment PriceFragment on SUS_ProductPrice {
    discount {
      amount_off
      percent_off
    }
    final_price {
      ...MoneyFragment
    }
    regular_price {
      ...MoneyFragment
    }
  }
`;
