import React, { ReactNode, useContext } from 'react';
import { ProductTrackingMeta } from './trackingTypes';

export const ProductTrackingContext = React.createContext<ProductTrackingMeta>(
  {}
);

export const ProductTrackingProvider = ({
  children,
  value,
  inherit = true,
}: {
  children?: ReactNode;
  value?: ProductTrackingMeta;
  inherit?: boolean;
}) => {
  const parentCtx = useContext(ProductTrackingContext);

  const providerValue =
    inherit && parentCtx ? { ...parentCtx, ...value } : value;

  return (
    <ProductTrackingContext.Provider value={providerValue}>
      {children}
    </ProductTrackingContext.Provider>
  );
};
