import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function Haendlerbund() {
  return (
    <StaticImage
      src="../../../assets/image/haendlerbund.png"
      alt="Händlerbund"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      height={91}
      loading="lazy"
    />
  );
}
