import React, { useRef } from 'react';
import { SimpleCart } from '../cart/SimpleCart';
import { useOutsideClick } from '@sus-core/hooks/useOutsideClick';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { HeaderButton } from './HeaderButton';
import { Icons } from '../icon/Icons';
import { Button } from '../button/Button';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';

export function HeaderCart({ className }: { className?: string }) {
  const { showCart, openCart, closeCart } = useAppService();

  const ref = useRef();
  useOutsideClick(ref, showCart, () => {
    setTimeout(() => {
      closeCart();
    });
  });

  const { content } = useCartService();

  const close = () => {
    closeCart();
  };

  return (
    <>
      <div
        className={className}
        onClick={() => {
          openCart();
        }}>
        <HeaderButton
          icon="Bag"
          label="Warenkorb"
          badge={content?.total_quantity || undefined}
        />
      </div>

      <div
        className={clsx(
          'fixed top-0 bottom-0 left-0 right-0 flex z-50 bg-gray-1',
          'transition-opacity',
          showCart && 'bg-opacity-50',
          !showCart && 'pointer-events-none bg-opacity-0'
        )}>
        <div
          ref={ref}
          className={clsx(
            'w-5/6 sm:w-2/3 lg:w-1/3  ml-auto bg-white-1 flex flex-col z-50',
            'transform',
            'transition-transform',
            !showCart && 'delay-75',
            showCart ? 'translate-x-0' : 'translate-x-full'
          )}>
          <span className="flex text-24 items-center px-6 pt-14 pb-8">
            Warenkorb
            <Icons.Close className="ml-auto cursor-pointer" onClick={close} />
          </span>

          <SimpleCart className="flex-1">
            <Link
              onClick={close}
              to="/warenkorb/"
              className="bg-primary text-white text-center p-4 rounded-lg ">
              Zum Warenkorb
            </Link>
            <Button size={1} variant="secondary" onClick={close}>
              Weiter einkaufen
            </Button>
          </SimpleCart>
        </div>
      </div>
    </>
  );
}
