import { Machine } from 'xstate/lib/Machine';
import { interpret } from 'xstate/lib/interpreter';
import { assign } from 'xstate/lib/actions';

import { storage } from '@sus-core/utils/window';
import {
  AUTH_TOKEN_KEY,
  gqlClient,
} from '@sus-core/api/client/sus-graphql-client';
import { CUSTOMER_CREATE_TOKEN, CUSTOMER_REVOKE_TOKEN } from '@sus-core/api';
import {
  CustomerToken,
  CustomerTokenVariables,
  RevokeCustomerToken,
  GetCustomer,
} from 'src/generated/api.types';
import { resolveWhen } from '@sus-core/state/utils/resolveWhen';

export interface AuthContext {
  token?: string;
  error?: string;
  customer?: GetCustomer['customer'];
}

interface AuthStateSchema {
  states: {
    pending: any;
    creatingToken: Record<string, unknown>;
    loggingout: Record<string, unknown>;
    unauthorized: Record<string, unknown>;
    authorized: Record<string, unknown>;
  };
}

interface LoginEvent {
  type: 'LOGIN';
  email: string;
  password: string;
}
interface LogoutEvent {
  type: 'LOGOUT';
  data: string;
}

interface AuthErrorEvent {
  type: 'AUTH_ERROR';
  data?: any;
}

type AuthEvent = LoginEvent | LogoutEvent | AuthErrorEvent;

const context: AuthContext = {
  token: storage.getItem(AUTH_TOKEN_KEY),
};

const authMachine = Machine<AuthContext, AuthStateSchema, AuthEvent>(
  {
    id: 'authentication',
    initial: 'pending',
    context,
    on: {
      AUTH_ERROR: {
        target: 'unauthorized',
        actions: ['clearStorage'],
      },
    },
    states: {
      pending: {
        always: [
          { cond: 'hasToken', target: 'authorized' },
          { cond: 'hasNoToken', target: 'unauthorized' },
        ],
      },
      authorized: {
        on: {
          LOGOUT: 'loggingout',
        },
      },
      unauthorized: {
        on: {
          LOGIN: 'creatingToken',
        },
      },
      creatingToken: {
        invoke: {
          id: 'create-customer-token',
          src: 'getToken',
          onDone: {
            target: 'authorized',
            actions: ['onSuccess', 'clearErrors'],
          },
          onError: {
            target: 'unauthorized',
            actions: ['clearStorage', 'onError'],
          },
        },
      },
      loggingout: {
        invoke: {
          id: 'revoke-customer-token',
          src: 'revokeToken',
          onDone: {
            target: 'unauthorized',
            actions: ['clearStorage', 'clearErrors'],
          },
          onError: {
            target: 'unauthorized',
            actions: ['clearStorage', 'onError'],
          },
        },
      },
    },
  },
  {
    guards: {
      hasToken: context => !!context.token,
      hasNoToken: context => !context.token,
    },
    services: {
      getToken: (context, event) =>
        gqlClient
          .mutate<CustomerToken, CustomerTokenVariables>({
            errorPolicy: 'all',
            mutation: CUSTOMER_CREATE_TOKEN,
            variables: {
              email: (event as LoginEvent).email,
              password: (event as LoginEvent).password,
            },
          })
          .then(result => {
            if (result.errors) {
              throw new Error(result.errors[0].message);
            }
            return result.data.generateCustomerToken.token;
          }),
      revokeToken: () =>
        gqlClient
          .mutate<RevokeCustomerToken, undefined>({
            mutation: CUSTOMER_REVOKE_TOKEN,
          })
          .then(result => result.data.revokeCustomerToken),
    },
    actions: {
      onSuccess: assign({
        token: (context, event) => {
          const token = (event as any).data;
          storage.setItem(AUTH_TOKEN_KEY, token);
          return token;
        },
      }),
      onError: assign({
        error: (context, event) => {
          return (event as any).data.message;
        },
      }),
      clearStorage: assign({
        token: (context, event) => {
          storage.removeItem(AUTH_TOKEN_KEY);
          return null;
        },
      }),
      clearErrors: assign({
        error: (context, event) => {
          return null;
        },
      }),
    },
  }
);

if (typeof window !== 'undefined') {
  window.addEventListener('AUTH_ERROR', () => {
    authService.send('AUTH_ERROR');
  });
}

export const authService = interpret(authMachine)
  // .onTransition(state => console.log('auth state:', state.value))
  .start();

export function login(email: string, password: string) {
  authService.send('LOGIN', { email, password });

  return resolveWhen({
    doneEventType: 'done.invoke.create-customer-token',

    service: authService,
  });
}
export function logout() {
  authService.send('LOGOUT', { data: null });

  return resolveWhen({
    doneEventType: 'done.invoke.revoke-customer-token',

    service: authService,
  });
}
