import React from 'react';
import { Tree } from '@sus-core/utils/categories';
import { RootLink } from './RootLink';
import { removeQueryParams } from '@sus-core/utils/url';
import clsx from 'clsx';

export function CategoryMenu({
  items,
  level = 0,
  className,
}: {
  items: Tree<GatsbyTypes.SUS_CategoryTree>[];
  level?: number;
  className?: string;
}) {
  return (
    <ul
      className={clsx(
        'flex flex-col flex-wrap gap-4 list-none m-0 p-0',
        className
      )}>
      {items.map(i => {
        const url = removeQueryParams(i.canonical_url);
        return (
          <li key={url} className="inline-block whitespace-nowrap">
            <RootLink
              className={clsx(
                level > 0 ? 'text-14 font-normal' : 'text-16 font-medium',
                'text-gray-1 whitespace-nowrap inline-block'
              )}
              to={url}>
              {level > 0 && <span className="mr-2">›</span>}
              {i.name}
            </RootLink>
            {!!i.children?.length && (
              <CategoryMenu
                className="mt-2"
                items={i.children}
                level={level + 1}
              />
            )}
          </li>
        );
      })}
    </ul>
  );
}
