import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { FilterItem } from '@sus-core/state/xstate/filter/filter.machine';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Button } from '../button/Button';
import { Form, FormProps } from '../form/Form';
import { Icons } from '../icon/Icons';

export interface FilterProps {
  loading?: boolean;
  items?: FilterItem[];
  className?: string;
  onChange?: (
    filters: Record<string, number | string | (number | string)[]>
  ) => void;
}

export function Filters({ className, onChange, items, loading }: FilterProps) {
  const handleFilterChange: FormProps['onChange'] = useCallback(
    filterValues => {
      onChange?.(filterValues);
    },
    [onChange]
  );
  return (
    <Form className={className} onChange={handleFilterChange}>
      <FilterFormContent
        items={items}
        onChange={values => onChange?.(values)}
        loading={loading}
      />
    </Form>
  );
}

function FilterFormContent({
  onChange,
  items,
  loading,
}: {} & Pick<FilterProps, 'items' | 'loading' | 'onChange'>) {
  const { reset, setValue, getValues } = useFormContext();

  useEffect(() => {
    // sync the active value to the form values
    if (items) {
      items.forEach(item => {
        const vals = item.activeValues?.map(activeVal => {
          return activeVal.value;
        });

        vals && setValue(item.id + '', vals.length === 1 ? vals[0] : vals);
      });
    }
  }, [items]);

  const currentFilters = Object.entries(getValues()).reduce(
    (map, [attributeCode, value]) => {
      if (!!(Array.isArray(value) ? value.length : value)) {
        return { ...map, [attributeCode]: value };
      }
      return map;
    },
    {}
  );

  return (
    <>
      <div className="text-24 mb-8 flex">
        <span className="relative">
          Filter
          {loading && (
            <FontAwesomeIcon
              className="absolute -right-10 top-2"
              icon={faSpinner}
              spin
            />
          )}
        </span>
        {Object.keys(currentFilters).length > 0 && (
          <Button
            className="ml-auto"
            size={2}
            variant="plain"
            onClick={() => {
              onChange?.({});
              reset();
            }}>
            alle löschen
          </Button>
        )}
      </div>

      <div>
        {items?.map(item => (
          <FilterSection
            disabled={loading}
            onRemove={(id, value) => {
              const currentValues = getValues()[id];
              if (Array.isArray(currentValues)) {
                setValue(
                  id + '',
                  currentValues.filter(v => v != value)
                );
              } else {
                setValue(id + '', undefined);
              }

              onChange?.(getValues());
            }}
            key={item.id}
            filter={item}
          />
        ))}
      </div>
    </>
  );
}

function FilterSection({
  disabled,
  filter,
  onRemove,
}: {
  disabled?: boolean;
  filter: FilterItem;
  onRemove: (id: string | number, value: any) => void;
} & Pick<FilterProps, 'onChange'>) {
  const [isExpanded, setExpanded] = useState<boolean>(true);
  const { register } = useFormContext();

  const { activeValues, label, id, options, multiSelect } = filter;

  const expandable = !!activeValues ? multiSelect && options.length > 1 : true;

  const expand = isExpanded && expandable;

  return (
    <div>
      <div
        className={clsx(
          'flex items-center',
          'border-t-default  border-gray-4 py-2',
          'text-17 font-medium',
          disabled && 'opacity-25',
          expand && 'border-b-default'
        )}
        onClick={() => expandable && setExpanded(!isExpanded)}>
        {label}
        {!!activeValues &&
          activeValues.map(activeValue => (
            <span
              key={`${activeValue.value}${id}}`}
              className="text-11 rounded-full px-2 py-1 bg-gray-5 inline-flex items-center ml-auto cursor-pointer"
              onClick={() => onRemove?.(id, activeValue.value)}>
              {activeValue.label || activeValue.value}
              <Icons.Close className="w-10px ml-2" />
            </span>
          ))}

        {expandable && (
          <span className="ml-auto mr-1">
            <Icons.ArrowDown
              className={clsx('transform', isExpanded && 'rotate-180')}
            />
          </span>
        )}
      </div>
      <ul
        className={clsx(
          'flex flex-col list-none gap-y-4 py-4 overflow-hidden',
          !expand && 'hidden',
          disabled && 'opacity-25'
        )}>
        {options.map((opt, idx) => (
          <li key={`${id}.${opt.value}.${idx}`} className="flex">
            <input
              id={`${id}.${opt.value}.${idx}`}
              disabled={disabled}
              type={multiSelect ? 'checkbox' : 'radio'}
              {...register(`${id}`)}
              value={opt.value}
            />
            <label htmlFor={`${id}.${opt.value}.${idx}`} className="ml-2">
              {opt.label} ({opt.count})
            </label>
          </li>
        ))}
      </ul>
    </div>
  );
}
