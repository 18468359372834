import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function Eisbaeren() {
  return (
    <StaticImage
      src="../../../assets/image/Eisbären_Berlin_Logo.png"
      alt="Eisbären Berlin Supporter Club"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      width={91}
      height={91}
      loading="lazy"
    />
  );
}
