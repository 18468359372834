import { EventObject } from 'xstate';
import { Interpreter } from 'xstate/lib/interpreter';

export function resolveWhen({
  doneEventType,
  service,
}: {
  doneEventType: string | RegExp;
  service: Interpreter<any>;
}): Promise<any> {
  return new Promise((resolve, reject) => {
    const listener = (event: EventObject) => {
      if (event.type.match(doneEventType)) {
        service.off(listener);
        resolve(event);
      }

      if (event.type.startsWith('error')) {
        service.off(listener);
        reject();
      }
    };
    service.onEvent(listener);
  });
}
