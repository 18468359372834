import React from 'react';
import { pricesAreEqual } from '@sus-core/utils/price';
import { ProductPrice } from './ProductPrice';
import { graphql } from 'gatsby';
import clsx from 'clsx';
import { MoneyData } from './MoneyView';

export type PriceRangeType = {
  maximum_price?: {
    final_price: MoneyData;
    regular_price: MoneyData;
    discount: {
      amount_off: number;
      percent_off: number;
    };
  };
  minimum_price: {
    final_price: MoneyData;
    regular_price: MoneyData;
    discount: {
      amount_off: number;
      percent_off: number;
    };
  };
};
export interface ProductPriceRangeProps {
  priceRange: PriceRangeType;
  className?: string;
  priceAdjustment?: number;
}
export function PriceRange({
  priceRange,
  priceAdjustment,
  className,
}: ProductPriceRangeProps) {
  const hasOnePrice = pricesAreEqual(
    priceRange.minimum_price.final_price,
    priceRange.maximum_price.final_price
  );

  const isDiscount =
    priceRange.minimum_price.discount.amount_off > 0 ||
    priceRange.minimum_price.discount.percent_off > 0;

  return (
    <div className={clsx(className)}>
      <ProductPrice
        adjustment={priceAdjustment}
        isDiscount={isDiscount}
        price={priceRange.minimum_price}
      />
      {!hasOnePrice && (
        <ProductPrice
          adjustment={priceAdjustment}
          price={priceRange.maximum_price}
        />
      )}
    </div>
  );
}

export const priceRangeFragment = graphql`
  fragment PriceRangeFragment on SUS_ProductInterface {
    price_range {
      maximum_price {
        ...PriceFragment
      }
      minimum_price {
        ...PriceFragment
      }
    }
  }
`;
