import React from 'react';
import clsx from 'clsx';

import { RootLink } from '../navigation/RootLink';

import { Link } from 'gatsby';
import { LogoutButton } from '../user/LogoutButton';
import { useCustomerService } from '@sus-core/state/xstate/customer/useCustomerService';
import { Logo } from '../Logo/Logo';

//@TODO import dynamically or remove completely before going live
import { SearchInput } from '../search/SearchInput';
import { HeaderButton } from './HeaderButton';
import { HeaderCart } from './HeaderCart';
import { PAGE_CONTAINER_CSS_NOPADDING } from '@sus-core/utils/cssClasses';
import { PopOver } from './PopOver';
import { useLocation } from '@reach/router';
import { getRedirectParam } from '../../utils/getRedirectParam';
import { MEDIA_QUERY, useMediaQuery } from '@sus-core/hooks/useMediaQuery';
import { Hamburger } from './Hamburger';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';

export function Header({
  className,
}: {
  className?: string;
  onHamburgerClick?: () => void;
}) {
  const { openMenu, closeSearch } = useAppService();
  const { isLoggedIn, customer } = useCustomerService();

  const { pathname } = useLocation();

  const isMobile = useMediaQuery(MEDIA_QUERY.IS_MOBILE);

  return (
    <header className={clsx('flex flex-col sticky top-0 z-10', className)}>
      <div
        className={clsx(
          'flex flex-row items-center relative pr-8',
          PAGE_CONTAINER_CSS_NOPADDING
        )}>
        {isMobile && <Hamburger onClick={openMenu} className="ml-4" />}

        <RootLink
          onClick={() => closeSearch()}
          className="mx-auto inline-flex justify-center"
          to="/">
          <Logo />
        </RootLink>

        {!isMobile && <SearchInput className="flex-1 mr-4" />}

        <PopOver
          className="mr-4"
          trigger={
            <HeaderButton
              icon="Person"
              label={
                isLoggedIn
                  ? `${customer?.firstname?.substring(0, 1) ?? ''} ${
                      customer?.lastname?.substring(0, 1) ?? ''
                    }`
                  : 'Anmelden'
              }
            />
          }>
          {!isLoggedIn ? (
            <>
              <Link
                onClick={() => closeSearch()}
                to={'/anmelden' + getRedirectParam(pathname)}
                className="btn-primary btn-s-2 whitespace-nowrap">
                Anmelden
              </Link>
              <Link
                onClick={() => closeSearch()}
                to="/konto-anlegen"
                className="btn-secondary btn-s-2  whitespace-nowrap">
                Konto anlegen
              </Link>
            </>
          ) : (
            <>
              <Link onClick={() => closeSearch()} to="/user">
                Mein Konto
              </Link>
              <LogoutButton />
            </>
          )}
        </PopOver>

        <HeaderCart />
      </div>
    </header>
  );
}
