import { symbolForCurrency, formatPrice } from '@sus-core/utils/price';
import clsx from 'clsx';
import React from 'react';

export function PriceValue({
  value,
  classes,
}: {
  value: { value: number; currency: string };
  classes?: { value?: string; currency?: string };
}) {
  return (
    <span className="whitespace-nowrap">
      <span className={clsx('mr-1', classes?.value)}>
        {value?.value !== undefined
          ? formatPrice(value.value, value.currency)
          : '--.--'}
      </span>
      {/* <span className={clsx(classes?.currency)}>
        {symbolForCurrency(value?.currency)}
      </span> */}
    </span>
  );
}
