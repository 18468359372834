import { useContext } from 'react';
import {
  trackAddToBasket,
  trackCheckout,
  trackCheckoutOption,
  trackProductClick,
  trackProductDetailImpression,
  trackProductsImpression,
  trackPurchase,
  trackRemoveFromBasket,
} from './tracking';
import { ProductTrackingContext } from './TrackingContext';

export const CHECKOUT_STEP_NUMBERS = {
  CART: 1,
  ADDRESS: 2,
  SHIPPING: 3,
  PAYMENT: 4,
};

export function useTracking() {
  const trackingMeta = useContext(ProductTrackingContext);

  const productsImpression: typeof trackProductsImpression = (
    products,
    scope = trackingMeta.scope,
    category = trackingMeta.category
  ) => trackProductsImpression(products, scope, category);

  const productClick: typeof trackProductClick = (
    product,
    position = trackingMeta.position,
    scope = trackingMeta.scope,
    category = trackingMeta.category
  ) => {
    trackProductClick(product, position, scope, category);
  };

  const productDetailImpression: typeof trackProductDetailImpression = (
    product,
    category = trackingMeta.category
  ) => {
    trackProductDetailImpression(product, category);
  };

  const addToBasket: typeof trackAddToBasket = (
    product,
    quantity,
    category = trackingMeta.category
  ) => trackAddToBasket(product, quantity, category);

  const removeFromBasket: typeof trackRemoveFromBasket = (
    product,
    quantity,
    category = trackingMeta.category
  ) => trackRemoveFromBasket(product, quantity, category);

  const checkoutStep: typeof trackCheckout = (step, items) =>
    trackCheckout(step, items);

  const checkoutOption: typeof trackCheckoutOption = (step, option) =>
    trackCheckoutOption(step, option);

  const purchase: typeof trackPurchase = (id, cart) => {
    trackPurchase(id, cart);
  };

  return {
    productsImpression,
    productClick,
    productDetailImpression,
    addToBasket,
    removeFromBasket,
    purchase,
    checkoutStep,
    checkoutOption,
  };
}
