import clsx from 'clsx';
import React from 'react';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';
import { PriceRow } from './PriceRow';
import { getSelectedShippingMethod } from '@sus-core/state/xstate/cart/getSelectedShippingMethod';
import { getAvailableShippingMethods } from '@sus-core/state/xstate/cart/getAvailableShippingMethods';

export function CartPrices({ className }: { className?: string }) {
  const { content } = useCartService();

  const availableShippingMethods = getAvailableShippingMethods(
    content?.shipping_addresses
  );

  const selectedShippingMethod = getSelectedShippingMethod(
    content?.shipping_addresses
  );

  const shippingMethod =
    selectedShippingMethod || availableShippingMethods.length === 1
      ? availableShippingMethods[0]
      : undefined;

  return (
    <div className={clsx('flex flex-col text-13', className)}>
      <PriceRow
        label="Zwischensumme:"
        value={content?.prices?.subtotal_excluding_tax}
      />
      <PriceRow
        label="Versand:"
        value={
          !!shippingMethod
            ? shippingMethod.amount
            : { value: undefined, currency: 'EUR' }
        }
      />

      {content?.prices?.applied_taxes?.map(tax => (
        <PriceRow key={tax.label} label={tax.label} value={tax.amount} />
      ))}

      <PriceRow
        className="border-t-2 border-gray-4 pt-2 font-semibold"
        label="Gesamtsumme:"
        value={content?.prices?.grand_total}
      />
    </div>
  );
}
