import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { PriceValue } from '@sus-core/components/product/price/PriceValue';

export function PriceRow({
  value,
  label,
  className,
}: {
  label: ReactNode;
  value: GatsbyTypes.SUS_Money;
  className?: string;
}) {
  return (
    <div className={clsx('flex justify-between mb-2', className)}>
      <span>{label}</span>
      <PriceValue classes={{ value: 'ml-auto mr-1' }} value={value} />
    </div>
  );
}
