import React from 'react';
import { Button } from '../button/Button';
import { useAuthService } from '@sus-core/state/xstate/auth/useAuthService';
// import { useCustomerToken } from '@sus-core/state/customer/useCustomerToken';

export function LogoutButton() {
  const { logout } = useAuthService();

  const handleClick = () => logout();

  return (
    <Button size={2} variant="primary" onClick={handleClick}>
      Abmelden
    </Button>
  );
}
