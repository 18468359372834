import { useEffect } from 'react';

export function useDisableBodyScrolling(disable: boolean) {
  useEffect(() => {
    if (disable) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [disable]);
}
