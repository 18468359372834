import React, { useState } from 'react';
import clsx from 'clsx';
import { ProductFilters } from './ProductFilters';
import {
  Aggregations,
  AttributeMetaData,
} from '@sus-core/state/xstate/filter/filter.machine';
import { Button } from '../button/Button';
import { Icons } from '../icon/Icons';

export function FilterTrigger({
  className,
  loading,
  meta,
  aggregations,
  onChange,
  filterId,
  resultCount,
}: {
  className?: string;
  loading?: boolean;
  meta: AttributeMetaData;
  aggregations: Aggregations;
  onChange: any;
  filterId: number | string;
  resultCount?: number;
}) {
  if (process.env.GATSBY_FEATURE_FILTERS !== 'true') {
    return null;
  }
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <Button
        className={className}
        size={2}
        variant="bordered"
        onClick={() => setOpen(true)}>
        <Icons.Filter className="inline" /> Filter
      </Button>

      <div
        className={clsx(
          'fixed top-0 bottom-0 left-0 right-0 z-20 bg-gray-1 bg-opacity-70 overflow-x-hidden',
          !open && 'hidden'
        )}>
        <div className="bg-white-1 mt-32 rounded-t-xl p-4 min-h-full">
          <ProductFilters
            filterId={filterId}
            className="w-full min-h-full"
            loading={loading}
            meta={meta}
            aggregations={aggregations}
            onChange={onChange}
          />
          <div className="fixed bottom-8 left-4 right-4 flex justify-center">
            <Button
              className="flex-1"
              size={2}
              variant="primary"
              onClick={() => setOpen(false)}>
              {resultCount !== undefined ? `(${resultCount})` : ''} Ergebnisse
              anzeigen
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
