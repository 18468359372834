import clsx from 'clsx';
import React from 'react';
import { Link } from 'gatsby';
import { PAGE_CONTAINER_CSS_NOPADDING } from '@sus-core/utils/cssClasses';
import { Icons } from '@sus-core/components/icon/Icons';
import * as styles from './Breadcrumbs.module.scss';

export type BreadcrumbItem = {
  name: string;
  target?: string;
};

export function Breadcrumbs({
  breadcrumbs,
}: {
  breadcrumbs: BreadcrumbItem[];
}) {
  return (
    <div className={clsx(PAGE_CONTAINER_CSS_NOPADDING, styles.root)}>
      <div className="flex gap-x-2 whitespace-nowrap overflow-x-auto">
        {breadcrumbs?.map((i, idx, arr) => (
          <React.Fragment key={idx}>
            <span>
              {i.target ? (
                <Link className="text-gray-2" to={i.target}>
                  {i.name}
                </Link>
              ) : (
                <span>{i.name}</span>
              )}
            </span>
            {idx < arr.length - 1 && (
              <span>
                <Icons.ArrowLeft className="transform rotate-180" />
              </span>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
