import * as styles from './PaymentIcons.module.scss';
import React, { ReactNode } from 'react';
import { ReactComponent as Paypal } from '@assets/icons/payment/PayPal.svg';
import { ReactComponent as Mastercard } from '@assets/icons/payment/Mastercard.svg';
import { ReactComponent as Visa } from '@assets/icons/payment/Visa.svg';
import { ReactComponent as Sofort } from '@assets/icons/payment/Sofort.svg';
import { ReactComponent as Klarna } from '@assets/icons/payment/Klarna.svg';
import { ReactComponent as Amazon } from '@assets/icons/payment/AmazonPay.svg';

const TextIcon = ({ children }: { children?: ReactNode }) => {
  return <div className={styles.texticon}>{children}</div>;
};

const Rechnung = () => {
  return <TextIcon>Rechnung</TextIcon>;
};

const Ueberweisung = () => {
  return <TextIcon>Über&shy;weisung</TextIcon>;
};

export const PaymentIcons = {
  Paypal,
  Mastercard,
  Visa,
  Sofort,
  Klarna,
  Amazon,
  Rechnung,
  Ueberweisung,
  TextIcon,
};
