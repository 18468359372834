import { useStaticQuery, graphql } from 'gatsby';

export function useSiteMetadata() {
  const { site } = useStaticQuery<GatsbyTypes.SiteMetadataQuery>(
    graphql`
      query SiteMetadata {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  return site?.siteMetadata;
}
