import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { GetCart_cart_items } from 'src/generated/api.types';

import { PriceValue } from '../product/price/PriceValue';
import { RootLink } from '../navigation/RootLink';
import { useCartService } from '@sus-core/state/xstate/cart/useCartService';

import { Button } from '../button/Button';
import { CartPrices } from './CartPrices';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useCartProductImages } from '@sus-core/hooks/useCartProductImages';
import { SUSImage } from '../media/SUSImage';

interface Props extends HTMLAttributes<HTMLElement> {
  className?: string;
}

export const SimpleCart = React.forwardRef(function SimpleCart(
  { className, children, ...rest }: Props,
  ref
) {
  const { content: cart } = useCartService();

  const hasItems = cart?.items && cart?.items.length > 0;
  return (
    <div
      className={clsx(
        'flex flex-col shadow-inner overflow-y-auto bg-white-1',
        className
      )}
      ref={ref as any}
      {...rest}>
      {hasItems && <SimpleCartItems />}

      {hasItems && (
        <div className="sticky bottom-0 bg-white-1 border-t-default border-gray-3">
          <CartPrices className="p-8" />

          <div className="mx-8 flex flex-col gap-y-4 md:mt-8 pb-4">
            {children}
          </div>
        </div>
      )}

      {!hasItems && (
        <div className="py-16 text-center">
          Sie haben noch keine Artikel in Ihrem Warenkorb.
        </div>
      )}
    </div>
  );
});

export function SimpleCartItems() {
  const { content: cart } = useCartService();
  return (
    <>
      {cart?.items?.map((item, idx, arr) => (
        <SimpleCartItem
          key={item.id}
          item={item}
          className={clsx(
            idx < arr.length - 1 && 'border-b-default border-gray-4'
          )}
        />
      ))}
    </>
  );
}

interface CartItemProps {
  item: GetCart_cart_items;
  className?: string;
}
function SimpleCartItem({ item, className }: CartItemProps) {
  const { loading, remove } = useCartService();
  const allImageData = useCartProductImages();
  const productImage = allImageData.find(
    prod => prod.node.sku === item.product.sku
  );

  const handleRemoveItem = (item: GetCart_cart_items) => () => {
    remove(item);
  };
  return (
    <div className={clsx('mx-8 py-8 flex flex-wrap md:flex-col', className)}>
      <div className="w-35px mr-4 md:w-75px md:mx-auto">
        {productImage && (
          <GatsbyImage
            backgroundColor="white"
            loading="lazy"
            className="mx-auto"
            image={getImage(productImage.node.image as any)}
            alt={item.product.name || ''}
          />
        )}
        {/* {!productImage && item.product.small_image?.url && (
          <SUSImage src={item.product.small_image.url} />
        )} */}
      </div>
      <div className="flex-1 flex flex-col">
        <div>
          {item.quantity}x
          <RootLink
            className="ml-1 text-gray-1 font-semibold"
            to={item.product.canonical_url}>
            {item.product.name}
          </RootLink>
        </div>
        <div className="mt-4 flex flex-col items-end">
          <PriceValue
            classes={{ currency: 'mr-2' }}
            value={item.prices.row_total_including_tax}
          />
          <Button
            className="text-11 mt-2 px-2 py-1 hover:text-gray-1 text-gray-3"
            size={null}
            variant="bordered"
            onClick={handleRemoveItem(item)}>
            {!loading && <FontAwesomeIcon icon={faTrash} />}
            {loading && <FontAwesomeIcon icon={faSpinner} spin />}
            <span className="ml-4">Entfernen</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
