import React, { ReactNode } from 'react';
import clsx from 'clsx';

import * as styles from './Seals.module.scss';

import { Eisbaeren } from '../icon/Eisbaeren';
import { Alba } from '../icon/Alba';
import { AktionMensch } from '../icon/AktionMensch';
import { Haendlerbund } from '../icon/Haendlerbund';

export function Seals({ className }: { className?: string }) {
  return (
    <div
      className={clsx(
        'grid md:grid-cols-4 md:gap-4 grid-cols-2 gap-2 px-2',
        className
      )}>
      <SealContainer href="http://www.albaberlin.de" target="_blank">
        <Alba />
      </SealContainer>
      <SealContainer
        href="https://logo.haendlerbund.de/show.php?uuid=f95b09da-ea96-11e9-a4fa-9c5c8e4fb375-5350201553&lang=ger"
        target="_blank">
        <Haendlerbund />
      </SealContainer>
      <SealContainer
        href="https://www.aktion-mensch.de/lotterie/geschaeftskunden/informationen/unser-siegel/?aktionmensch=partner_siegel"
        target="_blank">
        <AktionMensch />
      </SealContainer>
      <SealContainer
        href="https://2zsxf.r.a.d.sendibm1.com/mk/cl/f/dty8OENlCER6ChQxAWAz1BJhgc4dXMWaWqD_bFHVD5AupK3TqWPqtp_rh60_aZdtNjVq5vi7dXUDvThk-cCsFQ3PRRfejsF5NybnzZ3YeJPEryfROk3cNiPbRsFCS0yiKGy25SlYgaRHEKTGJSkzCb0ITsyyw184vNAAxllsMYjY4HVKjUjQNgHEPrceGpyWmffKhiu17FR_yKdT6Ro_Cd1VdkilHbqgwV1VVgap8LjENpy76tb10i0ya0J7tq3XrR8XEhoODxqc0g"
        target="_blank">
        <Eisbaeren />
      </SealContainer>
    </div>
  );
}

function SealContainer({
  children,
  className,
  ...rest
}: {
  children?: ReactNode;
  className?: string;
} & Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target'>) {
  return (
    <a {...rest} rel="noopener" className={clsx(className, styles.container)}>
      {children}
    </a>
  );
}
