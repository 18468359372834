import React from 'react';
import { useCMSPages } from '@sus-core/hooks/store/useCMSPages';
import { RootLink } from '../navigation/RootLink';

export interface CMSPagesLinksProps {
  classes?: { list?: string; item?: string; link?: string };
  storeId?: any;
  identifiers?: string[];
}

export function CMSPagesLinks({
  classes,
  storeId,
  identifiers,
}: CMSPagesLinksProps) {
  const cmsPages = useCMSPages(storeId);

  if (identifiers) {
    cmsPages.sort(
      (a, b) =>
        identifiers.indexOf(a.identifier) - identifiers.indexOf(b.identifier)
    );
  }

  return (
    <ul className={classes?.list}>
      {cmsPages
        .filter(page =>
          identifiers
            ? identifiers
                .map(id => id.toLowerCase())
                .includes(page.identifier.toLowerCase())
            : page
        )
        .map(page => (
          <li className={classes?.item} key={page.identifier}>
            <RootLink className={classes?.link} to={page.identifier}>
              {page.name}
            </RootLink>
          </li>
        ))}
    </ul>
  );
}
