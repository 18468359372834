import React, { useEffect, useState } from 'react';
import { ProductTile, ProductTileData, ProductTileProps } from './ProductTile';
import { Grid } from '../layout/Grid';
import clsx from 'clsx';
import { ProductTrackingProvider } from '@sus-core/hooks/tracking/TrackingContext';
import { DEFAULT_SORT, SortOption, SortOptions } from './SortOptions';
import { TrackingProduct } from '@sus-core/hooks/tracking/tracking';

export type ProductGridProps = {
  products?: readonly (TrackingProduct &
    ProductTileData & {
      options: readonly { required: boolean }[];
    })[];
  className?: string;
} & Pick<ProductTileProps, 'onNavigate'>;

function sortProducts(
  option: SortOption,
  products?: ProductGridProps['products']
) {
  switch (option) {
    case 'name_asc': {
      return products?.concat().sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    }
    case 'name_dsc': {
      return products?.concat().sort((a, b) => {
        return b.name.localeCompare(a.name);
      });
    }
    case 'price_asc': {
      return products?.concat().sort((a, b) => {
        return (
          a.price_range.minimum_price.final_price.value -
          b.price_range.minimum_price.final_price.value
        );
      });
    }
    case 'price_dsc': {
      return products?.concat().sort((a, b) => {
        return (
          b.price_range.minimum_price.final_price.value -
          a.price_range.minimum_price.final_price.value
        );
      });
    }
    default:
      return products;
  }
}

export function ProductGrid({
  products,
  className,
  onNavigate,
}: ProductGridProps) {
  const [sortedProducts, setSortedProducts] = useState(products);
  const [currentSort, setCurrentSort] = useState<SortOption>(DEFAULT_SORT);

  useEffect(() => {
    handleSortChange(currentSort);
  }, [products]);

  const handleSortChange = (option?: SortOption) => {
    setCurrentSort(option);
    setSortedProducts(sortProducts(option, products));
  };
  return (
    <div className={clsx('flex flex-col', className)}>
      <SortOptions
        onChange={handleSortChange}
        className="md:ml-auto text-center"
      />
      <Grid className={clsx('gap-x-6 gap-y-16')}>
        {sortedProducts?.map((product, idx) => (
          <div key={product.sku} className="p-2">
            <ProductTrackingProvider value={{ position: idx + 1 }}>
              <ProductTile
                imageLoading={idx > 2 ? 'lazy' : 'eager'}
                onNavigate={onNavigate}
                className="w-full h-full border border-ui-03"
                hasRequiredOptions={product.options?.some(opt => opt.required)}
                product={product}
              />
            </ProductTrackingProvider>
          </div>
        ))}
      </Grid>
    </div>
  );
}
