import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import clsx from 'clsx';
import { BreadcrumbList, ListItem } from 'schema-dts';
import { helmetJsonLdProp } from 'react-schemaorg';

import { ContentSection } from '@sus-core/components/content/ContentSection';
import { Navbar } from '@sus-core/components/navigation/Navbar';
import {
  BreadcrumbItem,
  Breadcrumbs,
} from '@sus-core/components/navigation/Breadcrumbs';
import { Header } from '@sus-core/components/Header/Header';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';

import { Footer } from './Footer';
import { Seals } from './Seals';

import { SearchResults } from '../search/SearchResults';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';
import { WindowLocation } from '@reach/router';
import { SuSLocationState } from '../navigation/SuSLocationState';
import { useSiteMetadata } from '@sus-core/hooks/useSiteMetadata';

type Props = {
  location: WindowLocation<SuSLocationState>;
  children?: ReactNode;
  meta: {
    title: string;
    description?: string;
    keywords?: string;
    canonical?: string;
    robots?: ('noindex' | 'index' | 'follow' | 'nofollow' | 'none')[];
  };
  seo?: {
    title?: string;
    type?: string;
    image?: string;
    url?: string;
    description?: string;
  };
  content?: {
    heading?: string;
    html?: string;
  };
  showBreadcrumbs?: boolean;
  breadcrumbs?: readonly GatsbyTypes.SUS_Breadcrumb[];
  currentPageName?: string;
  structuredData?: any[];
};

function useBreadcrumbItems(
  currentPageName: string,
  breadcrumbs?: readonly GatsbyTypes.SUS_Breadcrumb[]
): BreadcrumbItem[] {
  return [
    { name: 'Startseite', target: '/' },
    ...(breadcrumbs || []).map(item => ({
      name: item.category_name,
      target: '/' + item.category_url_path + '.html',
    })),
    currentPageName && { name: currentPageName },
  ].filter(_ => _);
}

function useBreadcrumbStructuredData(items: BreadcrumbItem[]) {
  const siteMeta = useSiteMetadata();

  const breadCrumbData = helmetJsonLdProp<BreadcrumbList>({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: items.map<ListItem>((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.name,
      item: item.target ? siteMeta.siteUrl + item.target : undefined,
    })),
  });

  return breadCrumbData;
}

export default function Layout({
  children,
  meta,
  seo,
  content,
  showBreadcrumbs = false,
  breadcrumbs,
  currentPageName,
  structuredData,
}: Props) {
  const { siteUrl } = useSiteMetadata();
  const { showSearch } = useAppService();
  const breadcrumbItems = useBreadcrumbItems(currentPageName, breadcrumbs);
  const breadCrumbData = useBreadcrumbStructuredData(breadcrumbItems);

  const canonicalUrl = meta?.canonical
    ? new URL(meta.canonical, siteUrl).href
    : undefined;
  const seoUrl = seo?.url ? new URL(seo.url, siteUrl).href : undefined;

  return (
    <>
      <Helmet
        title={currentPageName || seo?.title || meta.title}
        htmlAttributes={{
          lang: 'de',
        }}
        script={[
          ...(structuredData || []),
          showBreadcrumbs && breadCrumbData,
        ].filter(_ => _)}>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5.0, minimum-scale=0.86"
        />

        {meta.canonical && <link rel="canonical" href={canonicalUrl} />}
        {meta.description && (
          <meta name="description" content={meta.description} />
        )}

        {meta.robots && <meta name="robots" content={meta.robots.join(', ')} />}

        {seo?.type && <meta property="og:type" content={seo.type} />}
        {seo?.url && <meta property="og:url" content={seoUrl} />}
        {seo?.title && <meta property="og:title" content={seo.title} />}
        {seo?.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo?.image && <meta property="og:image" content={seo.image} />}

        {seo && <meta property="og:site_name" content="Schrank und Stuhl" />}
      </Helmet>

      <Header key="sus-header" />
      <Navbar />

      <main
        key="main-content"
        className={clsx(
          'flex-1 flex-shrink-0 min-h-full shadow-inner pb-16',
          showBreadcrumbs ? 'pt-4' : 'pt-16',
          showSearch && 'hidden'
        )}>
        {showBreadcrumbs && (
          <div className={clsx('bg-white-1 mb-16 overflow-x-hidden')}>
            <Breadcrumbs breadcrumbs={breadcrumbItems} />
          </div>
        )}
        {children}
      </main>

      {content?.html && (
        <div className={clsx(!showSearch && 'py-16')}>
          <ContentSection
            heading={content.heading}
            className={clsx(
              PAGE_CONTAINER_CSS,
              'flex flex-col gap-4',
              showSearch && 'hidden'
            )}
            content={content.html}
          />
        </div>
      )}
      <SearchResults />
      <div className="border-b-default border-t-default border-gray-4 py-16">
        <Seals className={PAGE_CONTAINER_CSS} />
      </div>

      <Footer className={PAGE_CONTAINER_CSS} key="sus-footer" />
    </>
  );
}
