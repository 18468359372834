import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function Avqp() {
  return (
    <StaticImage
      src="../../../assets/image/avqp_80.png"
      alt="AVQP"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      width={80}
    />
  );
}
export function AvqpMobile() {
  return (
    <StaticImage
      src="../../../assets/image/avqp_80.png"
      alt="AVQP"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      width={40}
    />
  );
}
