import { CustomerInput } from 'src/generated/api.types';
import { CustomerAddress } from '@sus-core/components/form/address/AddressEditor';
import { addressEquals } from '@sus-core/components/form/util/compare';
import { notifySuccess } from '@sus-core/utils/notify';
import { useActor } from '@xstate/react/lib/useActor';
import { useCallback } from 'react';

import {
  createCustomer,
  updateCustomer,
  createCustomerAddress,
  updateCustomerAddress,
  deleteCustomerAddress,
  customerService,
} from './customer.machine';

export function useCustomerService() {
  const [state] = useActor(customerService);

  const defaultBilling = state.context.customer?.addresses?.find(
    address => address.default_billing
  );
  const defaultShipping = state.context.customer?.addresses?.find(
    address => address.default_shipping
  );

  const customerLoading = state.matches('customer.updatingCustomer');
  const deletingAddress = state.matches('customer.deletingAddress');

  const update = (input: CustomerInput) =>
    updateCustomer(input).then(() =>
      notifySuccess('Die Änderungen wurden erfolgreich gespeichert.', 5000)
    );

  const containsAddress = useCallback(
    (address?: CustomerAddress): boolean => {
      if (!address) return false;
      return state.context?.customer?.addresses.some(savedAddress =>
        addressEquals(savedAddress, address)
      );
    },
    [state.context?.customer?.addresses]
  );

  return {
    state,
    createCustomer,
    update,
    createCustomerAddress,
    updateCustomerAddress,
    deleteCustomerAddress,
    containsAddress,
    error: state.context.error,
    loading: [
      'pending',
      'creating',
      'loading',
      'customer.creatingAddress',
      'customer.deletingAddress',
      'customer.updatingAddress',
      'customer.updatingCustomer',
    ].some(state.matches),
    customerLoading,
    deletingAddress,
    isLoggedIn: state.matches('customer'),
    customer: state.context.customer,
    defaultBilling,
    defaultShipping,
  };
}
