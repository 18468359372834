import { authService, login, logout } from './auth.machine';
import { useActor } from '@xstate/react/lib/useActor';

export function useAuthService() {
  const [state] = useActor(authService);

  const loading = ['creatingToken', 'loggingout'].some(state.matches);

  return {
    isAuthenticated: state.matches('authorized'),
    loading,
    login,
    logout,
    state,
  };
}
