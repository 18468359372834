import { useEffect, useState, MutableRefObject } from 'react';

export function useOutsideClick(
  ref: MutableRefObject<any>,
  hasFocus: boolean,
  callback: () => void = () => undefined
) {
  const [state] = useState<{ hasFocus: boolean }>({ hasFocus: false });

  state.hasFocus = hasFocus;

  const getHasFocus = () => state.hasFocus;

  function handleClickOutside(event: MouseEvent) {
    if (getHasFocus() && ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
}
