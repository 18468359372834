import debounce from 'lodash/debounce';
import React, {
  FormHTMLAttributes,
  ReactNode,
  useCallback,
  useEffect,
} from 'react';
import {
  useForm,
  FormProvider,
  UseFormReturn,
  UseFormProps,
} from 'react-hook-form';

type UseFormPropsSlice = Pick<
  UseFormProps,
  'mode' | 'criteriaMode' | 'reValidateMode' | 'defaultValues'
>;

export type FormProps = {
  onSubmit?: (
    values: Record<string, any>,
    state: UseFormReturn['formState'],
    formMethods: UseFormReturn
  ) => void;
  onChange?: (
    values: Record<string, any>,
    state: UseFormReturn['formState']
  ) => void;
  render?: (formMethods: UseFormReturn) => ReactNode;
  changeDelay?: number;
  onValidityChange?: (valid: boolean) => void;
} & Omit<FormHTMLAttributes<HTMLFormElement>, 'onSubmit' | 'onChange'> &
  UseFormPropsSlice;

export function Form({
  children,
  render,
  onSubmit,
  onChange,
  defaultValues,
  changeDelay = 1,
  onValidityChange,
  mode = 'onChange',
  criteriaMode,
  reValidateMode,
  ...rest
}: FormProps) {
  const formMethods = useForm({
      mode,
      criteriaMode,
      reValidateMode,
      defaultValues,
    }),
    { handleSubmit, getValues, formState, reset } = formMethods;

  const debouncedChange = useCallback(
    onChange ? debounce(onChange, changeDelay) : _ => _,
    [onChange]
  );

  const submitCallback = values => {
    onSubmit?.(values, formState, formMethods);
  };

  useEffect(() => {
    if (reset && defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  useEffect(() => {
    onValidityChange?.(formState.isValid);
  }, [formState.isValid]);

  const handleChange = () => debouncedChange?.(getValues(), formState);

  useEffect(() => {
    // rerender when the dirty state changes
    // => trigger call of render function
  }, [formState.dirtyFields, formState.errors, formState.isValid]);

  return (
    <FormProvider {...formMethods}>
      <form
        onChange={handleChange}
        onSubmit={handleSubmit(submitCallback)}
        {...rest}>
        {render ? render(formMethods) : children}
      </form>
    </FormProvider>
  );
}
