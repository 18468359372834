import {
  CUSTOMER_CREATE,
  CUSTOMER_UPDATE,
  CUSTOMER_CREATE_ADDRESS,
  CUSTOMER_DELETE_ADDRESS,
  CUSTOMER_GET,
  CUSTOMER_UPDATE_ADDRESS,
} from '..';
import {
  CreateCustomer,
  CreateCustomerAddress,
  CreateCustomerAddressVariables,
  CreateCustomerVariables,
  CustomerAddressInput,
  CustomerInput,
  DeleteCustomerAddress,
  DeleteCustomerAddressVariables,
  GetCustomer,
  UpdateCustomer,
  UpdateCustomerAddress,
  UpdateCustomerAddressVariables,
  UpdateCustomerVariables,
} from '../../../generated/api.types';
import { gqlClient } from '../client/sus-graphql-client';

export const createCustomer = (input: CustomerInput) =>
  gqlClient.mutate<CreateCustomer, CreateCustomerVariables>({
    mutation: CUSTOMER_CREATE,
    variables: { input },
    errorPolicy: 'none',
  });

export const createCustomerAddress = (input: CustomerAddressInput) =>
  gqlClient.mutate<CreateCustomerAddress, CreateCustomerAddressVariables>({
    mutation: CUSTOMER_CREATE_ADDRESS,
    variables: { input },
    errorPolicy: 'all',
  });

export const updateCustomerAddress = (
  variables: UpdateCustomerAddressVariables
) =>
  gqlClient.mutate<UpdateCustomerAddress, UpdateCustomerAddressVariables>({
    mutation: CUSTOMER_UPDATE_ADDRESS,
    variables,
    errorPolicy: 'all',
  });

export const deleteCustomerAddress = (
  variables: DeleteCustomerAddressVariables
) =>
  gqlClient.mutate<DeleteCustomerAddress, DeleteCustomerAddressVariables>({
    mutation: CUSTOMER_DELETE_ADDRESS,
    variables,
    errorPolicy: 'all',
  });

export const getCustomer = () =>
  gqlClient.query<GetCustomer>({
    query: CUSTOMER_GET,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

export const updateCustomer = (input: CustomerInput) =>
  gqlClient.mutate<UpdateCustomer, UpdateCustomerVariables>({
    mutation: CUSTOMER_UPDATE,
    variables: {
      input,
    },
    errorPolicy: 'all',
  });

export const customerApi = {
  getCustomer,
  createCustomer,
  updateCustomer,
  createCustomerAddress,
  updateCustomerAddress,
  deleteCustomerAddress,
};
