import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import { Tree } from '@sus-core/utils/categories';
import { removeQueryParams } from '@sus-core/utils/url';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';
import { useOutsideClick } from '@sus-core/hooks/useOutsideClick';

import { RootLink } from './RootLink';
import { Icons } from '../icon/Icons';
import { SearchInput } from '../search/SearchInput';
import { AvqpMobile } from '../icon/Avqp';
import { Pqvol40 } from '../icon/Pqvol';

export function MobileNav({
  menuCategories,
}: {
  menuCategories: Tree<GatsbyTypes.SUS_CategoryTree>[];
}) {
  const { showMenu, closeMenu } = useAppService();
  const ref = useRef();
  useOutsideClick(ref, showMenu, () => {
    setTimeout(() => {
      closeMenu();
    });
  });

  return (
    <nav
      className={clsx(
        'fixed md:hidden top-0 bottom-0 left-0 right-0 flex z-50 bg-gray-1 transition-opacity',
        showMenu ? 'bg-opacity-50' : 'bg-opacity-0',
        !showMenu && 'pointer-events-none'
      )}>
      <div
        ref={ref}
        className={clsx(
          'w-5/6 sm:w-2/3 lg:w-1/3  mr-auto bg-white-1 flex flex-col z-50',
          'transform',
          'transition-transform',
          !showMenu && 'delay-75',
          showMenu ? 'translate-x-0' : '-translate-x-full'
        )}>
        <div
          className="p-4 flex items-center cursor-pointer"
          onClick={closeMenu}>
          <Icons.Close className="mr-4" /> Menü
        </div>
        <div className="p-4 shadow-inner border-b-default border-gray-4">
          <SearchInput />
        </div>
        <Menu className="relative" menuCategories={menuCategories} />
        <div className="flex items-center gap-x-4 m-4">
          <AvqpMobile />
          <Pqvol40 />
        </div>
      </div>
    </nav>
  );
}

type MenuProps = {
  onBackClick?: () => void;
  parent?: Tree<GatsbyTypes.SUS_CategoryTree>;
  className?: string;
  menuCategories: Tree<GatsbyTypes.SUS_CategoryTree>[];
  onAnimationStart?: () => void;
  onAnimationEnd?: () => void;
};

function Menu({
  onBackClick,
  parent,
  className,
  menuCategories,
  onAnimationEnd,
  onAnimationStart,
}: MenuProps) {
  const { closeMenu } = useAppService();

  const [activeChild, setActiveChild] = useState<number>();

  return (
    <div
      className={clsx('h-full overflow-x-hidden', className)}
      onAnimationEnd={onAnimationEnd}
      onAnimationStart={onAnimationStart}>
      {parent && (
        <>
          <div
            className="flex p-4 bg-blue-1 text-white-1 cursor-pointer sticky top-0"
            onClick={onBackClick}>
            <Icons.ArrowLeft />
            {parent.name}
          </div>
          <div className="cursor-pointer">
            <RootLink
              className="text-gray-1 p-4 font-medium block border-b-default border-gray-4"
              to={removeQueryParams(parent.canonical_url)}
              onClick={closeMenu}>
              Alle anzeigen
            </RootLink>
          </div>
        </>
      )}
      <ul
        className={clsx(
          'h-full m-0 p-0',
          !!activeChild && 'overflow-y-hidden'
        )}>
        {menuCategories.map(category => {
          const url = removeQueryParams(category.canonical_url);
          return (
            <li key={category.id + category.canonical_url}>
              {!category.children || !category.children?.length ? (
                <RootLink
                  className="text-gray-1 p-4 block border-b-default border-gray-4 cursor-pointer"
                  to={url}
                  onClick={closeMenu}>
                  {category.name}
                </RootLink>
              ) : (
                <div
                  className="text-gray-1 p-4 flex justify-between items-center border-b-default border-gray-4 cursor-pointer"
                  onClick={() => setActiveChild(category.id)}>
                  <span>{category.name}</span>
                  <Icons.ArrowDown className="transform -rotate-90 z-10" />
                </div>
              )}
            </li>
          );
        })}
      </ul>
      {menuCategories.map(category => {
        if (!!category?.children) {
          return (
            <SlideInMenu
              key={category.id + category.canonical_url}
              parent={category}
              onBackClick={() => setActiveChild(undefined)}
              show={!!activeChild && activeChild === category.id}
              className={clsx(
                'absolute top-0 bottom-0 left-0 right-0 bg-white-1 z-50'
              )}
              menuCategories={category.children}
            />
          );
        }
      })}
    </div>
  );
}

function SlideInMenu({ show, ...props }: MenuProps & { show: boolean }) {
  const [mounted, setMounted] = useState<boolean>(false);
  const [idle, setIdle] = useState<boolean>(true);

  useEffect(() => {
    if (show) {
      setIdle(false);
    }
    setMounted(show);

    return () => {};
  }, [show]);

  const handleAnimationEnd = () => {
    if (!mounted) setIdle(true);
  };

  return (
    <>
      <Menu
        {...props}
        onAnimationEnd={handleAnimationEnd}
        className={clsx(
          props.className,
          idle && 'hidden',
          mounted ? 'animate-slide-in-right' : 'animate-slide-out-right'
        )}
      />
    </>
  );
}
