import { useActor } from '@xstate/react/lib/useActor';
import { useCallback, useEffect } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useAppService } from '../app/useAppService';

import {
  search,
  searchService,
  clearSearch,
  SearchParams,
} from './search.machine';

export function useSearch() {
  const { closeSearch, openSearch, showSearch } = useAppService();
  const [query, setQuery] = useQueryParam('sq', StringParam);
  const [state] = useActor(searchService);

  const searchFn = useCallback((params: SearchParams) => {
    setQuery(params.query);
    return search(params);
  }, []);

  useEffect(() => {
    if (showSearch && !query && state.context.searchQuery) {
      setQuery(state.context.searchQuery);
    }

    if (!showSearch && query) {
      setQuery(undefined);
    }
  }, [query, state.context.searchQuery, showSearch]);

  return {
    isLoading: state.matches('loading'),
    state,
    context: state.context,
    search: searchFn,
    closeSearch,
    openSearch,
    showSearch,
    clearSearch: () => {
      setQuery(undefined);
      clearSearch();
      closeSearch();
    },
    query,
    aggregationsForQuery:
      state.context.aggregationsForQuery?.[state.context.searchQuery],
  };
}
