import React, {
  useState,
  useRef,
  ReactElement,
  ReactNode,
  HTMLAttributes,
  useEffect,
} from 'react';
import clsx from 'clsx';
import { useOutsideClick } from '@sus-core/hooks/useOutsideClick';

type Positions = 'center' | 'left' | 'right';

let popupID = 1;

const getId = () => {
  const id = popupID++;
  if (id === Number.MAX_SAFE_INTEGER) {
    popupID = 0;
    return popupID++ + '';
  }

  return id + '';
};

export function PopOver({
  position = 'center',
  trigger,
  children,
  className,
  onMount,
  onShow,
}: {
  position?: Positions;
  trigger: ReactElement<HTMLAttributes<HTMLElement>>;
  children: ReactNode;
  className?: string;
  onMount?: (id: string, close: () => void) => void;
  onShow?: (id: string) => void;
}) {
  const [id] = useState(() => getId());
  const [show, _setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>();

  const setShow = (value: boolean) => {
    _setShow(value);

    if (value) {
      onShow?.(id);
    }
  };

  useOutsideClick(ref, true, () => {
    setTimeout(() => setShow(false));
  });
  const containerRef = useRef();
  const classes = classesForPosition(position);

  useEffect(() => {
    onMount?.(id, () => setShow(false));
  }, []);

  return (
    <div ref={containerRef} className={clsx('relative', className)}>
      {React.cloneElement(trigger, {
        ...trigger.props,
        onMouseOver: () => setShow(true),
        onClick: () => setShow(true),
      })}
      {show && (
        <div
          ref={ref}
          onMouseLeave={() => setShow(false)}
          className={clsx(
            classes.container,
            'flex flex-col mt-4 p-4 gap-2 absolute z-50 top-full  bg-white-1 border-default border-gray-4 rounded-lg shadow-lg'
          )}>
          <span
            className={clsx(
              'block absolute w-10px h-10px bg-white-1 border-t-default border-l-default border-gray-4 -top-px rotate-45',
              classes.indicator
            )}
          />
          {children}
        </div>
      )}
    </div>
  );
}

function classesForPosition(
  position: Positions
): { indicator: string; container: string } {
  switch (position) {
    case 'center':
      return {
        container: 'left-1/2 transform -translate-x-1/2',
        indicator: 'left-1/2 transform -translate-x-1/2 -translate-y-1/2',
      };
    case 'left':
      return {
        container: 'left-0 -ml-4',
        indicator: 'left-8 transform translate-x-1/2 -translate-y-1/2',
      };
    case 'right':
      return {
        container: 'right-0 transform -translate-x-1/2',
        indicator: 'right-0 transform -translate-x-1/2 -translate-y-1/2',
      };
  }
}
