import clsx from 'clsx';
import React from 'react';

import * as styles from './Hamburger.module.scss';

export function Hamburger({
  className,
  onClick,
}: {
  className?: string;
  onClick?: () => void;
}) {
  return (
    <div onClick={onClick} className={clsx('flex flex-col p-2', className)}>
      <span className={styles.bar} />
      <span className={styles.bar} />
      <span className={styles.bar} />
    </div>
  );
}
