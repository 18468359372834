import { GetCart_cart_prices } from 'src/generated/api.types';
import { getCurrencyFormat } from './formats';

export function subtractTax(price: number, taxRate = 0.19): number {
  return (price / ((1 + taxRate) * 100)) * 100;
}

export function pricesAreEqual(
  priceA: { currency: string; value: number },
  priceB: { currency: string; value: number }
): boolean {
  return moneyIsEqual(priceA, priceB);
}

export function moneyIsEqual(
  a: { currency: string; value: number },
  b: { currency: string; value: number }
): boolean {
  return a.currency === b.currency && a.value === b.value;
}

export function symbolForCurrency(currency: string): string {
  const currencySymbolMap = {
    EUR: '€',
  };

  return currencySymbolMap[currency] || currency;
}

export function getTaxAmount(prices?: GetCart_cart_prices): number {
  const withTaxes = prices?.grand_total?.value || 0;
  const withoutTaxes = prices?.subtotal_excluding_tax?.value || 0;
  return withTaxes - withoutTaxes;
}

export function formatPrice(value: number, currency: string = 'EUR'): string {
  if (value === null || value === undefined || value === NaN) {
    return value as any;
  }
  return getCurrencyFormat(currency).format(value);
}
