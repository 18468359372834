import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Icons } from '../icon/Icons';
import { useFormContext } from 'react-hook-form';

function clamp(value: number, min: number, max: number): number {
  return Math.max(Math.min(value, max), min);
}

export function AmountInput({
  loading,
  quantity = 1,
  onChange,
  disableFormContext,
  min = 1,
  max = 1000,
}: {
  min?: number;
  max?: number;
  disableFormContext?: boolean;
  quantity: number;
  loading?: boolean;
  onChange?: (amount: number) => void;
}) {
  quantity = clamp(quantity, min, max);

  const formContext = useFormContext();
  const [value, setValue] = useState<number>(quantity);

  useEffect(() => {
    setValue(quantity);
  }, [quantity]);

  const onAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = Math.min(Math.max(parseInt(e.target.value), min), max);
    if (!isNaN(amount)) {
      setValue(amount);
      onChange?.(amount);
    }
  };

  const isUsedInForm = !disableFormContext && formContext !== null;

  const inputProps = isUsedInForm ? formContext.register('amount', {}) : {};

  return (
    <div className={clsx('flex', loading && 'opacity-50')}>
      <button
        disabled={loading}
        className="rounded-l-lg border-default border-gray-4 p-2"
        onClick={e => {
          e.preventDefault();

          if (isUsedInForm) {
            const current: number = parseInt(formContext.getValues()['amount']);
            formContext.setValue('amount', Math.max(min, current - 1));
          } else {
            const newAmount = Math.max(min, value - 1);
            setValue(newAmount);
            newAmount !== value && onChange?.(newAmount);
          }
        }}>
        <Icons.Minus />
      </button>
      <input
        disabled={loading}
        style={{ maxWidth: '70px' }}
        className={clsx(
          'flex-none border-t-default border-b-default rounded-lg rounded-l-none rounded-r-none p-2 text-center',
          'border-gray-4 appearance-none'
        )}
        type="number"
        name="amount"
        value={isUsedInForm ? undefined : value}
        onChange={e => {
          onAmountChange(e);
        }}
        {...inputProps}
      />

      <button
        disabled={loading}
        className="rounded-r-lg border-default border-gray-4 p-2"
        onClick={e => {
          e.preventDefault();
          if (isUsedInForm) {
            const current: number = parseInt(formContext.getValues()['amount']);
            formContext.setValue('amount', Math.min(current + 1, max));
          } else {
            const newAmount = Math.min(value + 1, max);
            setValue(newAmount);
            newAmount !== value && onChange?.(newAmount);
          }
        }}>
        <Icons.Plus />
      </button>
    </div>
  );
}
