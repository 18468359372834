import clsx from 'clsx';
import React from 'react';

export type SortOption = 'name_asc' | 'name_dsc' | 'price_asc' | 'price_dsc';

export const DEFAULT_SORT: SortOption = 'price_asc';

export function SortOptions({
  defaultValue = DEFAULT_SORT,
  className,
  onChange,
}: {
  defaultValue?: SortOption;
  className?: string;
  onChange?: (value: SortOption) => void;
}) {
  return (
    <form className={clsx('rounded-lg p-2', className)}>
      <span className="mr-2">Sortiert nach</span>
      <select
        className="text-right"
        defaultValue={defaultValue}
        onChange={e => onChange?.(e.target.value as SortOption)}>
        <option value="price_asc">Preis aufsteigend &#8593;</option>
        <option value="price_dsc">Preis absteigend &#8595;</option>
        <option value="name_asc">Name aufsteigend &#8593;</option>
        <option value="name_dsc">Name absteigend &#8595;</option>
      </select>
    </form>
  );
}
