import { AttributeMetaData } from '@sus-core/state/xstate/filter/filter.machine';
import React, { ReactNode, useState } from 'react';

export interface PageContextValue {
  update: (values: Partial<PageMeta>) => void;
  data: PageMeta;
}

export interface PageMeta {
  pageType?:
    | 'Landing'
    | 'Category'
    | 'Search Results'
    | 'Detail'
    | 'Checkout'
    | 'Customer Account'
    | 'Login'
    | 'CMS'
    | '404';
  filtersActive?: boolean;
  categoryId?: number;
  category?: string;
  categoryUrlPath?: string;
  attributeMetaData?: AttributeMetaData;
  breadcrumbs?: GatsbyTypes.SUS_Breadcrumb[];
}

export const PageContext = React.createContext<PageContextValue>({
  data: {},
  update: () => {},
});

export function PageProvider({
  children,
  data,
}: {
  children?: ReactNode;
  data: PageMeta;
}) {
  const [meta, setMeta] = useState<PageMeta>(data);

  const update: PageContextValue['update'] = values =>
    setMeta(state => ({ ...state, values }));

  const value: PageContextValue = {
    update,
    data: meta,
  };

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
}
