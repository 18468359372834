import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { PAGE_CONTAINER_CSS } from '@sus-core/utils/cssClasses';
import clsx from 'clsx';
import React, { useCallback, useEffect } from 'react';
import { Button } from '../button/Button';
import { useSearch } from '@sus-core/state/xstate/search/useSearch';
import { Icons } from '../icon/Icons';
import { useTracking } from '@sus-core/hooks/tracking/useTracking';

import { usePagemeta } from '@sus-core/hooks/page/usePagemeta';
import { ProductGrid, ProductGridProps } from '../product/ProductGrid';
import {
  getFilterInputParams,
  ProductFilterProps,
  ProductFilters,
} from '../product/ProductFilters';

import { FilterTrigger } from '../product/FilterTrigger';
import { MEDIA_QUERY, useMediaQuery } from '@sus-core/hooks/useMediaQuery';
import { useAllProductImages } from '@sus-core/hooks/useProductImages';
import { SearchProducts_products_items_SimpleProduct } from 'src/generated/api.types';

export function SearchResults() {
  const isMobile = useMediaQuery(MEDIA_QUERY.IS_MOBILE);
  const {
    search,
    isLoading,
    context,
    closeSearch,
    showSearch,
    aggregationsForQuery,
  } = useSearch();

  const imageData = useAllProductImages();

  const pagemeta = usePagemeta();

  const tracking = useTracking();
  useEffect(() => {
    if (showSearch && context?.searchResults?.products?.items?.length) {
      tracking.productsImpression(
        context?.searchResults?.products?.items || [],
        'Search Results'
      );
    }
  }, [showSearch, context?.searchResults?.products?.items]);

  const handleProductNavigate = useCallback(() => {
    closeSearch();
  }, []);

  const handleFilterChange: ProductFilterProps['onChange'] = useCallback(
    (filterValues, updateAggregations) => {
      search({
        currentPage: 1,
        filter: getFilterInputParams(
          pagemeta.data.attributeMetaData,
          filterValues
        ),
      }).then(result => {
        updateAggregations(result.data?.products?.aggregations || []);
      });
    },
    [pagemeta.data.attributeMetaData, search]
  );

  if (!showSearch) return null;

  const productsWithMedia: ProductGridProps['products'] =
    context.searchResults?.products.items.map(p => {
      const image = imageData.find(i => i.node.sku + '' === p.sku + '');
      return {
        categories: p.categories,
        price_tiers: p.price_tiers,
        price_range: p.price_range,
        options: (p as SearchProducts_products_items_SimpleProduct).options,
        id: p.id,
        name: p.name,
        sku: p.sku,
        canonical_url: p.canonical_url,
        image: image?.node?.image,
        __typename: p.__typename,
      };
    });

  return (
    <section
      className={clsx('overflow-y-auto overflow-x-hidden bg-white pb-16')}>
      <Button className="m-2" variant="plain" size={2} onClick={closeSearch}>
        Suche schließen
      </Button>
      <div className={clsx(PAGE_CONTAINER_CSS)}>
        <h3>Suchergebnisse für "{context.searchQuery}"</h3>
        <div className="flex justify-between items-center p-2 bg-blue-3">
          {
            <Button
              disabled={
                isLoading ||
                context?.searchResults?.products?.page_info?.current_page === 1
              }
              size={2}
              variant="plain"
              onClick={() => {
                search({
                  currentPage:
                    context.searchResults?.products?.page_info?.current_page -
                    1,
                });
              }}>
              <Icons.ArrowLeft />
            </Button>
          }
          <div className="flex flex-col items-center">
            <span>
              Seite {context?.searchResults?.products?.page_info?.current_page}{' '}
              von {context?.searchResults?.products?.page_info?.total_pages}
            </span>
            <span className="text-xs">
              {context?.searchResults?.products?.items?.length || 0} Ergebnisse
              {context?.searchResults?.products?.total_count &&
              context?.searchResults?.products?.total_count >
                context?.searchResults?.products?.items?.length
                ? ' von ' + context?.searchResults?.products?.total_count
                : null}
            </span>
          </div>

          {
            <Button
              disabled={
                isLoading ||
                context?.searchResults?.products?.page_info?.current_page ===
                  context?.searchResults?.products?.page_info?.total_pages
              }
              size={2}
              variant="plain"
              onClick={() => {
                search({
                  currentPage:
                    context?.searchResults?.products?.page_info?.current_page +
                    1,
                });
              }}>
              <Icons.ArrowLeft className="transform rotate-180" />
            </Button>
          }
        </div>

        {isLoading && (
          <div className="flex justify-center m-8 text-21">
            <FontAwesomeIcon icon={faSpinner} spin className="mr-4" />
          </div>
        )}
        <div className={clsx('mt-8', isLoading && 'pointer-events-none')}>
          <div className={clsx('flex items-start', 'flex-col md:flex-row')}>
            {!isMobile && (
              <ProductFilters
                filterId="search-filters"
                className={clsx(
                  'w-full max-w-250 mr-4',
                  !pagemeta?.data?.attributeMetaData && 'hidden'
                )}
                meta={pagemeta?.data?.attributeMetaData}
                aggregations={aggregationsForQuery}
                onChange={handleFilterChange}
                loading={isLoading}
              />
            )}
            {isMobile && (
              <FilterTrigger
                resultCount={context?.searchResults?.products?.items?.length}
                className="mb-4 w-full"
                filterId="search-filters"
                meta={pagemeta?.data?.attributeMetaData}
                aggregations={aggregationsForQuery}
                onChange={handleFilterChange}
                loading={isLoading}
              />
            )}
            <ProductGrid
              onNavigate={handleProductNavigate}
              products={productsWithMedia}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
