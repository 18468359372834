import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export function Alba() {
  return (
    <StaticImage
      src="../../../assets/image/alba.png"
      alt="Alba Berlin Company Club"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      height={91}
      loading="lazy"
    />
  );
}
