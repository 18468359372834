import React, { useState } from 'react';
import clsx from 'clsx';
import get from 'lodash/get';

import {
  useFormContext,
  RegisterOptions,
  ValidationValueMessage,
  ChangeHandler,
} from 'react-hook-form';

let fieldIdCounter = 0;
export interface FormFieldProps extends RegisterOptions {
  children?: React.ReactElement<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    any
  >;
  name: string;
  label?: string;
  error?: string;
  className?: string;
  labelClass?: string;
  layout?: 'col' | 'row';
  noHint?: boolean;
  plain?: boolean;
}

export function FormField({
  name,
  children,
  label,
  error,
  className,
  layout = 'col',
  labelClass,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  validate,
  noHint,
  plain,
  ...rest
}: FormFieldProps) {
  const formContext = useFormContext();
  const [fieldId] = useState(() => `form-field-${fieldIdCounter++}`);
  const validationError = get(formContext?.formState.errors, name);
  const hasError = !!validationError || error;
  error = error || validationError?.message;

  const isRequired =
    rest.required ||
    (rest.required as ValidationValueMessage)?.value ||
    children.props.required;

  return (
    <>
      <div
        className={clsx(
          'flex',
          `flex-${layout}`,

          'gap-x-2',
          error && 'border border-error',
          className
        )}>
        {label && (
          <label
            className={clsx('font-semibold text-15 mb-1', labelClass)}
            htmlFor={fieldId}>
            {label}

            {!noHint && !isRequired && (
              <span className="text-gray-2 ml-1 text-11">(optional)</span>
            )}
            {!noHint && isRequired && <sup>*</sup>}
          </label>
        )}
        {React.cloneElement(children, {
          ...children.props,
          id: fieldId,
          name: name || children.props.name,
          required: !!(rest.required || children.props.required),
          ...(() => {
            const fieldProps = formContext?.register(
              name || children.props.name,
              {
                required: rest.required || children.props.required,
                pattern,
                min: min ?? children.props.min,
                minLength: minLength ?? children.props.minLength,
                max: max ?? children.props.max,
                maxLength: maxLength ?? children.props.maxLength,
                validate: validate,
              }
            );
            const changeHandler = fieldProps?.onChange;

            if (changeHandler) {
              fieldProps.onChange = e => {
                children.props?.onChange?.(e as any);
                return changeHandler(e);
              };
            }
            return fieldProps;
          })(),

          className: clsx(
            !plain && 'border-default border-gray-4 p-2 rounded-lg',
            children.props.className,
            hasError && 'border-danger bg-red-2 focus:bg-white bg-opacity-20'
          ),
        })}

        {error && <div className="w-full text-11 text-danger">{error}</div>}
      </div>
    </>
  );
}
