export const ALL_STORES_ID = 0;

export function pageBelongsToStore(
  storeIds: readonly number[],
  storeId: number
) {
  /**
   * when a page is assigned to all storeviews the store id is 0
   */

  return storeIds.includes(storeId) || storeIds.includes(0);
}
