import { PriceRange } from '@sus-core/components/product/price/PriceRange';
import { GetCart_cart, GetCart_cart_items } from 'src/generated/api.types';
import { ImpressionData, PageScope, ProductData } from './trackingTypes';

interface Money {
  readonly currency: string;
  readonly value: number;
}

export interface TrackingProduct {
  sku: string;
  name: string;
  price_range: PriceRange;
}
function publish(data: any) {
  process.env.NODE_ENV === 'development' &&
    console.debug(`[tracking] push:\n
  data = ${JSON.stringify(data, null, 2)}`);

  dataLayer.push(data);
}

export function mapSusProductsToTracking(
  products: readonly TrackingProduct[],
  category?: string,
  list?: PageScope
): ImpressionData[] {
  return products.map((p, idx) => ({
    id: p.sku,
    name: p.name,
    position: idx + 1,
    category,
    list,
  }));
}

export function trackProductsImpression(
  products: readonly TrackingProduct[],
  scope?: PageScope,
  category?: string
) {
  const mappedProducts = mapSusProductsToTracking(products, category, scope);

  publish({ ecommerce: null });
  publish({
    event: 'impressions_eec',
    ecommerce: {
      impressions: mappedProducts,
    },
  });
}

export function trackProductClick(
  product: TrackingProduct,
  position?: number | null,
  scope?: PageScope,
  category?: string | null
) {
  publish({ ecommerce: null });
  publish({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: { list: scope },
        products: [
          {
            id: product.sku,
            name: product.name,
            category: category != null ? category : undefined,
            position: position != null ? position : undefined,
          },
        ] as ProductData[],
      },
    },
  });
}

const getProductPrice = (product: TrackingProduct): string => {
  const price =
    product.price_range?.minimum_price?.final_price?.value ||
    product.price_range?.maximum_price?.final_price?.value;

  return price ? price.toFixed(2) : '';
};

export function trackProductDetailImpression(
  product: TrackingProduct,
  category?: string | null
) {
  const price = getProductPrice(product);

  publish({ ecommerce: null });
  publish({
    event: 'product_detail',
    ecommerce: {
      detail: {
        actionField: { list: 'Detail' },
        products: [
          {
            name: product.name,
            id: product.sku,
            price,
            category,
          },
        ] as ProductData[],
      },
    },
  });
}

export function trackAddToBasket(
  product: TrackingProduct,
  quantity: number,
  category?: string | null
) {
  const price = getProductPrice(product);

  publish({ ecommerce: null });
  publish({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [
          {
            name: product.name,
            id: product.sku,
            price,
            category,
            quantity,
          },
        ] as ProductData[],
      },
    },
  });
}

export function trackRemoveFromBasket(
  item: GetCart_cart_items,
  quantity: number,
  category?: string | null
) {
  const price = item.prices.price.value;

  publish({ ecommerce: null });
  publish({
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products: [
          {
            name: item.product.name,
            id: item.product.sku,
            price,
            category,
            quantity,
          },
        ],
      },
    },
  });
}

export function trackCheckout(step: number, items: GetCart_cart_items[]) {
  const products = items.map<ProductData>(item => ({
    id: item.product.sku,
    name: item.product.name,
    quantity: item.quantity,
    price: item.prices.price.value.toFixed(2),
  }));

  publish({ ecommerce: null });
  publish({
    event: 'checkout',
    currencyCode: 'EUR',
    ecommerce: {
      checkout: {
        actionField: { step },
        products: products,
      },
    },
  });
}

export function trackCheckoutOption(step: number, checkoutOption: string) {
  publish({ ecommerce: null });
  publish({
    event: 'checkoutOption',
    ecommerce: {
      checkout_option: {
        actionField: { step: step, option: checkoutOption },
      },
    },
  });
}

export function trackPurchase(transactionId: string, cart: GetCart_cart) {
  const products = cart.items.map<ProductData>(item => ({
    id: item.product.sku,
    name: item.product.name,
    quantity: item.quantity,
    price: item.prices.price.value.toFixed(2),
  }));

  publish({ ecommerce: null });
  publish({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId,
          affiliation: 'Touchpoint',
          revenue: cart.prices.grand_total.value.toFixed(2),
          tax: cart.prices.applied_taxes
            .reduce((sum, curr) => sum + curr.amount.value, 0)
            .toFixed(2),
          shipping: '0.00',
        },
        products,
      },
    },
  });
}
