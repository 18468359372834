import { ReactComponent as ArrowDown } from '@assets/icons/Arrow-Down.svg';
import { ReactComponent as ArrowLeft } from '@assets/icons/Arrow-Left.svg';
import { ReactComponent as Bag } from '@assets/icons/Bag.svg';
import { ReactComponent as Check } from '@assets/icons/Check.svg';
import { ReactComponent as Close } from '@assets/icons/Close.svg';
import { ReactComponent as Danger } from '@assets/icons/Danger.svg';
import { ReactComponent as Edit } from '@assets/icons/Edit.svg';
import { ReactComponent as Filter } from '@assets/icons/Filter.svg';
import { ReactComponent as Minus } from '@assets/icons/Minus.svg';
import { ReactComponent as NotAllowed } from '@assets/icons/Not-Allowed.svg';
import { ReactComponent as Person } from '@assets/icons/Person.svg';
import { ReactComponent as Plus } from '@assets/icons/Plus.svg';
import { ReactComponent as Star } from '@assets/icons/Star.svg';

import { ReactComponent as Search } from '@assets/icons/Search.svg';

export const Icons = {
  ArrowDown,
  ArrowLeft,
  Bag,
  Check,
  Close,
  Danger,
  Edit,
  Filter,
  Minus,
  NotAllowed,
  Person,
  Plus,
  Search,
  Star,
};
