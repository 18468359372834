import { useDisableBodyScrolling } from '@sus-core/hooks/useDisableBodyScrolling';
import { useActor } from '@xstate/react/lib/useActor';

import {
  appService,
  openMenu,
  closeMenu,
  openCart,
  closeCart,
  openSearch,
  closeSearch,
  getFilters,
} from './app.machine';

export function useAppService() {
  const [state] = useActor(appService);
  const showMenu = state.matches('mobile.menu.open');
  const showCart = ['mobile.sidecart.open', 'desktop.sidecart.open'].some(
    state.matches
  );
  const showSearch = ['mobile.search.open', 'desktop.search.open'].some(
    state.matches
  );
  useDisableBodyScrolling(showCart || showMenu);

  return {
    // isMobile: state.matches('mobile'),
    showCart,
    showMenu,
    showSearch,
    state,
    openMenu,
    closeMenu,
    openCart,
    closeCart,
    openSearch,
    closeSearch,
    getFilters,
    filters: state.context.filters,
  };
}
