import React, { HTMLAttributes } from 'react';
import { Icons } from '../icon/Icons';
import { Badge } from '../badge/Badge';
import { MEDIA_QUERY, useMediaQuery } from '@sus-core/hooks/useMediaQuery';

export function HeaderButton({
  icon,
  label,
  badge,
  ...rest
}: {
  label?: string;
  badge?: string | number;
  icon: keyof typeof Icons;
} & HTMLAttributes<HTMLDivElement>) {
  const IconComp = Icons[icon];

  const isMobile = useMediaQuery(MEDIA_QUERY.IS_MOBILE);
  return (
    <div
      className="flex flex-col items-center text-11 cursor-pointer max-w-75"
      {...rest}>
      <span className="relative">
        <IconComp width="25" height="25" />
        {badge > -1 && (
          <Badge className="absolute top-0 right-0 -mr-1 rounded-full bg-blue-1 text-white">
            {badge}
          </Badge>
        )}
      </span>
      {label && !isMobile && (
        <span className="block mt-2 overflow-ellipsis">{label}</span>
      )}
    </div>
  );
}
