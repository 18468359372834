import { assign } from 'xstate/lib/actions';
import { Machine } from 'xstate/lib/Machine';
import { interpret } from 'xstate/lib/interpreter';
import {
  ProductAttributeFilterInput,
  SearchProducts,
  SearchProducts_products_aggregations,
} from 'src/generated/api.types';
import { searchProducts } from '@sus-core/api/service/products';
import { resolveWhen } from '@sus-core/state/utils/resolveWhen';

type Aggregations = SearchProducts_products_aggregations[];

export interface SearchContext {
  searchResults?: SearchProducts;
  searchQuery?: string;
  filter?: ProductAttributeFilterInput;
  currentPage: number;
  error?: any;
  aggregationsForQuery?: Record<string, Aggregations>;
}

interface SearchStateSchema {
  states: {
    idle: any;
    loading: any;
  };
}

interface SearchEvent {
  type: 'SEARCH';
  query: string;
  filter?: ProductAttributeFilterInput;
  currentPage?: number;
}

interface ClearSearchEvent {
  type: 'SEARCH_CLEAR';
}

type SearchEvents = SearchEvent | ClearSearchEvent;

const searchMachine = Machine<SearchContext, SearchStateSchema, SearchEvents>(
  {
    id: 'search',
    initial: 'idle',
    context: {
      currentPage: 1,
    },
    on: {
      SEARCH: {
        target: 'loading',
        actions: assign<SearchContext, SearchEvent>((ctx, event) => {
          return {
            searchQuery:
              event.query === undefined ? ctx.searchQuery : event.query,
            filter: event.filter === undefined ? ctx.filter : event.filter,
            currentPage:
              event.currentPage === undefined
                ? ctx.currentPage
                : Math.max(1, event.currentPage || 1),
          };
        }),
      },
      SEARCH_CLEAR: {
        target: 'idle',
        actions: assign<SearchContext, ClearSearchEvent>({
          searchQuery: null,
          filter: null,
          currentPage: null,
          searchResults: null,
          aggregationsForQuery: null,
        }),
      },
    },
    states: {
      idle: {},
      loading: {
        invoke: {
          id: 'search-products',
          src: 'search',
          onDone: {
            target: 'idle',
            actions: assign((ctx, event) => {
              const results = event.data as SearchProducts;

              const hasAggregationsForQuery =
                !!ctx.aggregationsForQuery?.[ctx.searchQuery];

              return hasAggregationsForQuery
                ? { searchResults: results }
                : {
                    searchResults: results,
                    aggregationsForQuery: {
                      ...(ctx.aggregationsForQuery || {}),
                      [ctx.searchQuery]: results.products.aggregations,
                    },
                  };
            }),
          },
          onError: {
            target: 'idle',
            actions: assign({
              error: (ctx, event) => event.data,
            }),
          },
        },
      },
    },
  },
  {
    services: {
      search: ctx => {
        return searchProducts(
          ctx.searchQuery === null ? '' : ctx.searchQuery,
          ctx.filter === null ? undefined : ctx.filter,
          ctx.currentPage
        ).then(result => result.data);
      },
    },
  }
);

export const searchService = interpret(searchMachine).start();

export type SearchParams = {
  query?: string;
  filter?: ProductAttributeFilterInput;
  currentPage?: number;
};
export function search({ query, currentPage, filter }: SearchParams) {
  searchService.send({
    type: 'SEARCH',
    query,
    currentPage,
    filter,
  });

  return resolveWhen({
    doneEventType: 'done.invoke.search-products',
    service: searchService,
  });
}

export function clearSearch() {
  searchService.send('SEARCH_CLEAR');
}
