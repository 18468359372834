import { usePagemeta } from '@sus-core/hooks/page/usePagemeta';
import { Link, GatsbyLinkProps } from 'gatsby';

import React from 'react';

interface CanonicalLinkProps extends GatsbyLinkProps<any> {
  ref?: React.Ref<any>;
}

export function RootLink({ to, children, state, ...rest }: CanonicalLinkProps) {
  const pagemeta = usePagemeta();
  if (to && !to.startsWith('/')) {
    to = `/${to}`;
  }
  return (
    <Link to={to} state={{ prev: pagemeta.data, ...(state || {}) }} {...rest}>
      {children}
    </Link>
  );
}
