import {
  addCartItem,
  cartService,
  removeCartItem,
  updateCartItems,
  setCartAddresses,
  setCartShippingMethod,
  setCartPaymentMethod,
  placeOrderOnCart,
} from './cart.machine';
import { useActor } from '@xstate/react/lib/useActor';
import { useCustomerService } from '../customer/useCustomerService';
import {
  CHECKOUT_STEP_NUMBERS,
  useTracking,
} from '@sus-core/hooks/tracking/useTracking';
import {
  AddProductVariables,
  CartItemUpdateInput,
  GetCart_cart_items,
  PaymentMethodInput,
} from 'src/generated/api.types';
import { TrackingProduct } from '@sus-core/hooks/tracking/tracking';

export interface OptionParameter {
  id: number;
  value_string: string;
}

export type AddOperation = (
  sku: string,
  quantity: number,
  options?: OptionParameter[]
) => void;

export function useCartService() {
  const tracking = useTracking();
  const { isLoggedIn } = useCustomerService();
  const [state] = useActor(cartService);

  const add = (
    product: TrackingProduct,
    quantity,
    options?: Omit<AddProductVariables, 'cartId'>['options']
  ) => {
    tracking.addToBasket(product, quantity);
    return addCartItem({ quantity, sku: product.sku, options });
  };

  const remove = (item: GetCart_cart_items) => {
    tracking.removeFromBasket(item, item.quantity);
    return removeCartItem(parseInt(item.id));
  };

  const placeOrder = () => {
    const cart = state.context.content;
    return placeOrderOnCart().then(event => {
      const orderNumber = event?.data?.order_number;
      tracking.purchase(orderNumber, cart);
    });
  };

  const update = (data: CartItemUpdateInput[]) => {
    data.forEach(d => {
      const current = state.context.content.items.find(
        item => item.id === d.cart_item_id + ''
      );

      const diff = d.quantity - current.quantity;
      if (diff > 0) {
        tracking.addToBasket(
          {
            name: current.product.name,
            sku: current.product.sku,
            price_range: {
              minimum_price: {
                final_price: current.prices.price,
                regular_price: current.prices.price,
              },
            },
          },
          diff
        );
      } else if (diff < 0) {
        tracking.removeFromBasket(current, Math.abs(diff));
      }
    });
    return updateCartItems(data);
  };

  const setPaymentMethod = (data: PaymentMethodInput) => {
    tracking.checkoutOption(CHECKOUT_STEP_NUMBERS.PAYMENT, data.code);
    return setCartPaymentMethod(data);
  };

  return {
    state,
    cartId: isLoggedIn ? state.context.cartId : state.context.guestCartId,
    add,
    update,
    remove,
    loading:
      state.matches('loading') ||
      state.matches('creating') ||
      state.matches('adding') ||
      state.matches('removing'),
    content: state.context.content,
    removingProduct: state.context.productToRemoveId,
    productToAdd: state.context.productToAdd,
    setAddresses: setCartAddresses,
    setShippingMethod: setCartShippingMethod,
    setPaymentMethod,
    placeOrder,
    ordering: state.matches('placeOrder'),
    isCartEmpty: state.context.content?.items?.length === 0,
  };
}
