export type Tree<T = Record<string, any>> = T & {
  children?: Tree<T>[];
};

export function flattenTree<T>(tree: Tree<T>, flat: Tree<T>[] = []) {
  if (!tree?.children) return flat;

  flat.push(...tree.children);
  tree?.children?.forEach(child => flattenTree(child, flat));
  return flat;
}

export function filterTree<T>(
  tree: Tree<T>,
  filterFn: (item: any) => boolean,
  sortFn?: (a: T, b: T) => any
) {
  if (!tree?.children) return tree;

  const copy = { ...tree };
  copy.children = copy.children.filter(filterFn).map(item => {
    return filterTree(item, filterFn);
  });

  if (sortFn) {
    copy.children.sort(sortFn);
  }

  return copy;
}
