const REDIRECTS = { '/konto-anlegen': '/', '/customer/*': '/' };

export function getRedirectParam(pathname?: string) {
  const wildCard = Object.keys(REDIRECTS)
    .filter(e => e.endsWith('*'))
    .find(e => pathname.startsWith(e.substr(0, e.length - 1)));

  let newPath = pathname;

  if (wildCard) {
    newPath = REDIRECTS[wildCard];
  }

  if (REDIRECTS[pathname]) {
    newPath = REDIRECTS[pathname];
  }

  return newPath ? `?to=${newPath}` : '';
}
