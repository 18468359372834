import clsx from 'clsx';
import React from 'react';
import * as styles from './Badge.module.scss';

type BadgeProps = {
  children?: React.ReactNode;
  className?: string;
};

export function Badge({ children, className }: BadgeProps) {
  return <span className={clsx(styles.root, className)}>{children}</span>;
}
