import { useStaticQuery, graphql } from 'gatsby';
import { pageBelongsToStore } from '@sus-core/utils/belongsToStore';

export function useCMSPages(storeId?: any) {
  const data = useStaticQuery<GatsbyTypes.CMSPagesQuery>(graphql`
    query CMSPages {
      sus {
        cmsPages {
          allPages {
            id
            identifier
            is_active
            name
            stores
          }
        }
      }
    }
  `);

  return data.sus.cmsPages.allPages.filter(page =>
    storeId ? pageBelongsToStore(page.stores, storeId) : true
  );
}
