import { useStaticQuery, graphql } from 'gatsby';

export function useCartProductImages() {
  const { allMagentoProduct } =
    useStaticQuery<GatsbyTypes.CartProductImageQueryQuery>(
      graphql`
        query CartProductImageQuery {
          allMagentoProduct {
            edges {
              node {
                sku
                image {
                  childImageSharp {
                    gatsbyImageData(
                      height: 75
                      width: 75
                      transformOptions: { fit: CONTAIN }
                    )
                  }
                }
              }
            }
          }
        }
      `
    );
  return allMagentoProduct.edges;
}
