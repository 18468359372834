import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, FormProps } from '../form/Form';
import { FormField } from '../form/FormField';
import { Icons } from '../icon/Icons';

import debounce from 'lodash/debounce';
import { useSearch } from '@sus-core/state/xstate/search/useSearch';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';

export type SearchInputProps = {
  className?: string;
};

export function SearchInput({ className }: SearchInputProps) {
  const { closeMenu } = useAppService();
  const [hasFocus, setFocus] = useState<boolean>(false);

  const {
    search,
    isLoading,
    context,
    showSearch,
    openSearch,
    query,
    clearSearch,
  } = useSearch();

  const dispatchSearch = (q: string = query) => {
    if (!showSearch) {
      openSearch();
    }
    // reset filters when query changes
    search({ query: q, filter: null, currentPage: 1 });
  };

  const handleFocus = () => {
    setFocus(true);
    if (context?.searchResults?.products?.items?.length) {
      openSearch();
    }
  };

  const handleSubmit: FormProps['onSubmit'] = ({ search }) => {
    dispatchSearch(search);
    closeMenu();
  };

  useEffect(() => {
    if (query && !showSearch) {
      if (query !== context?.searchQuery) {
        dispatchSearch();
      }
    }

    if (query && query !== context?.searchQuery) {
      dispatchSearch();
    }
  }, [query]);

  const handleChange = useCallback(
    debounce(({ search }) => {
      if (search && search.length > 2) {
        dispatchSearch(search);
      }

      if (!search) {
        clearSearch();
      }
    }, 600),
    []
  );

  return (
    <Form
      onChange={handleChange}
      onSubmit={handleSubmit}
      defaultValues={!hasFocus ? { search: context.searchQuery } : undefined}
      className={clsx(
        'flex px-4 py-3 rounded-md items-center bg-gray-5 text-15 text-gray-2',
        className
      )}
      render={({ setValue }) => {
        return (
          <>
            <Icons.Search className="mr-2" />
            <div className="flex-1">
              <FormField name="search" plain>
                <input
                  onFocus={handleFocus}
                  onBlur={() => setFocus(false)}
                  type="text"
                  className="w-full bg-gray-5"
                  required
                  placeholder="Suchbegriff eingeben"
                />
              </FormField>
            </div>
            {isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin className="mr-4" />
            )}

            {!isLoading && !!context?.searchResults?.products && (
              <div
                className={clsx(
                  'flex justify-end items-center p-2 text-11 cursor-pointer border-default border-gray-4 rounded-full hover:bg-gray-2 hover:text-white'
                )}>
                <span
                  className=""
                  onClick={() => {
                    closeMenu();
                    openSearch();
                  }}>
                  {context.searchResults.products.total_count} Ergebnisse
                </span>
              </div>
            )}

            {!isLoading && !!context?.searchResults?.products && (
              <span
                className="text-11 p-2 cursor-pointer border-default border-gray-4 ml-4 rounded-full hover:bg-gray-2 hover:text-white"
                onClick={() => {
                  setValue('search', '');
                  clearSearch();
                }}>
                <span>löschen</span>
                <Icons.Close className="ml-2 inline-block" />
              </span>
            )}
          </>
        );
      }}
    />
  );
}
