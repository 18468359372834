import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

export function Pqvol80() {
  return (
    <StaticImage
      src="../../../assets/image/pqvol.png"
      alt="PQVOL"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      width={80}
    />
  );
}
export function Pqvol40() {
  return (
    <StaticImage
      src="../../../assets/image/pqvol.png"
      alt="PQVOL"
      layout="fixed"
      placeholder="blurred"
      quality={100}
      width={40}
    />
  );
}
