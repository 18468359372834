import React from 'react';
import 'toasted-notes/src/styles.css';
import toast from 'toasted-notes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome/index';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { Button } from '@sus-core/components/button/Button';

const Success = ({ message }: { message: string }) => (
  <div className="flex items-center">
    <FontAwesomeIcon className="text-success mr-2" icon={faCheck} />
    <span className="text-success text-lg">{message}</span>
  </div>
);
const Error = ({ message }: { message: string }) => (
  <div className="flex items-center">
    <FontAwesomeIcon
      className="text-danger mr-2"
      icon={faExclamationTriangle}
    />
    <span className="text-danger text-lg">{message}</span>
  </div>
);

const Warning = ({ message }: { message: string }) => (
  <div className="flex items-center">
    <FontAwesomeIcon className="text-star mr-2" icon={faExclamationTriangle} />
    <span className="text-star text-lg">{message}</span>
  </div>
);

export function notifySuccess(message: string, duration?: number) {
  toast.notify(<Success message={message} />, { duration: duration || null });
}
export function notifyError(
  message: string,
  duration?: number,
  onCloseCallback?: () => void
) {
  onCloseCallback
    ? toast.notify(
        ({ onClose }) => (
          <div className="bg-white-1 rounded-lg p-4 shadow-lg">
            <Error message={message} />

            <Button
              size={2}
              variant="secondary"
              onClick={() => {
                onClose();
                onCloseCallback?.();
              }}>
              OK
            </Button>
          </div>
        ),
        {
          duration: duration || null,
        }
      )
    : toast.notify(<Error message={message} />, {
        duration: duration || null,
      });
}
export function notifyWarning(message: string, duration?: number) {
  toast.notify(<Warning message={message} />, { duration: duration || null });
}
