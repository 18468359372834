import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';
export function Grid({
  children,
  className,
}: Pick<HTMLAttributes<HTMLElement>, 'children' | 'className'>) {
  return (
    <div
      className={clsx(
        'grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-3',
        className
      )}>
      {children}
    </div>
  );
}
