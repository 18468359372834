import React, { useState } from 'react';
import { Tree } from '@sus-core/utils/categories';
import { RootLink } from './RootLink';
import { removeQueryParams } from '@sus-core/utils/url';

import clsx from 'clsx';
import { PAGE_CONTAINER_CSS_NOPADDING } from '@sus-core/utils/cssClasses';
import { PopOver } from '../Header/PopOver';
import { CategoryMenu } from './CategoryMenu';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';
import { Pqvol80 } from '../icon/Pqvol';
import { Avqp } from '../icon/Avqp';

export function DesktopNav({
  menuCategories,
}: {
  menuCategories: Tree<GatsbyTypes.SUS_CategoryTree>[];
}) {
  const { showSearch } = useAppService();
  const [popovers] = useState(new Map<string, () => void>());
  return (
    <nav className={clsx('hidden', showSearch ? 'md:hidden' : 'md:block')}>
      <div className={clsx('flex', PAGE_CONTAINER_CSS_NOPADDING)}>
        <ul className="list-none flex-1 flex flex-row flex-wrap items-center justify-center">
          {menuCategories.map(category => {
            const url = removeQueryParams(category.canonical_url);
            const link = (
              <RootLink className="text-gray-1 hover:underline" to={url}>
                {category.name}
              </RootLink>
            );
            return (
              <li key={url} className="mx-8 my-6">
                {!!category.children?.length ? (
                  <PopOver
                    onMount={(id, close) => {
                      popovers.set(id, close);
                    }}
                    onShow={id => {
                      popovers.forEach((closeFn, key) => {
                        if (key !== id) closeFn();
                      });
                    }}
                    trigger={link}
                    position="left">
                    <CategoryMenu className="p-4" items={category.children} />
                  </PopOver>
                ) : (
                  link
                )}
              </li>
            );
          })}
        </ul>
        <div className="self-start flex flex-col items-center gap-6 mt-5 mr-8 mb-8">
          <Avqp />
          <Pqvol80 />
        </div>
      </div>
    </nav>
  );
}
