import React, { HTMLAttributes } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import clsx from 'clsx';

import * as styles from './ProductTile.module.scss';

import { RootLink } from '../navigation/RootLink';
import { PriceRange } from './price/PriceRange';
import { MoneyView, MoneyData } from './price/MoneyView';
import { AddToCartButton } from '../cart/AddToCartButton';
import { navigate } from 'gatsby';
import { useCartService } from '../../state/xstate/cart/useCartService';
import { useAppService } from '@sus-core/state/xstate/app/useAppService';
import { useTracking } from '@sus-core/hooks/tracking/useTracking';
import { usePagemeta } from '@sus-core/hooks/page/usePagemeta';
import { PageMeta } from '@sus-core/hooks/page/PageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { TrackingProduct } from '@sus-core/hooks/tracking/tracking';

interface CategoryData {
  // canonical_url: string;
  url_path: string;
}

interface ImageData {
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData;
  };
}
// extends Pick<GatsbyTypes.MagentoProduct['image'], 'childImageSharp'> {}

export interface ProductTileData {
  image?: ImageData;
  canonical_url: string;
  min_sales_qty?: number;
  categories: readonly CategoryData[];
  price_tiers: readonly {
    final_price: MoneyData;
  }[];
}

export type ProductTileProps = {
  // product: ProductWithMedia;
  product: ProductTileData & TrackingProduct;
  hasRequiredOptions: boolean;
  imageLoading?: 'lazy' | 'eager';
  onNavigate?: (target: string) => void;
} & HTMLAttributes<HTMLElement>;

function getProductLink(product: ProductTileData, pageMeta: PageMeta) {
  if (!product) {
    return '';
  }

  if (!product.categories || product.categories.length != 1) {
    return `/${product.canonical_url}`;
  }

  const category = product.categories[0];
  const isIsCurrentCategory =
    !!category.url_path &&
    !!pageMeta.categoryUrlPath &&
    category.url_path === pageMeta.categoryUrlPath;
  // baseUrl:
  // 1. pageMeta category == product category
  // 2. if only one product category
  //    then product category_url_path
  // 3. if multi categories
  //    then root
  if (isIsCurrentCategory) {
    return `/${pageMeta.categoryUrlPath}/${product.canonical_url}`;
  } else if (category.url_path) {
    return `/${category.url_path}/${product.canonical_url}`;
  }
  return `/${product.canonical_url}`;
}

export function ProductTile({
  product,
  imageLoading = 'lazy',
  className,
  onNavigate,
  hasRequiredOptions
}: ProductTileProps) {
  const tracking = useTracking();
  const pagemeta = usePagemeta();
  const { add, productToAdd, loading } = useCartService();
  const { openCart } = useAppService();
  // const { options } = product as GatsbyTypes.SUS_SimpleProduct;
  // const hasRequiredOptions = options?.some(opt => !!opt.required);

  const target = getProductLink(product, pagemeta.data);

  const handleAdd = (quantity: number) => {
    // if (
    //   product['__typename'] === 'SUS_SimpleProduct' ||
    //   product['__typename'] === 'SimpleProduct'
    // ) {
    if (hasRequiredOptions) {
      tracking.productClick(product);
      onNavigate?.(target);
      navigate(target, {
        state: { prev: pagemeta.data }
      });
      return;
    }
    // }

    add(product, quantity).then(() => openCart());
  };

  const isAdding = productToAdd && product && productToAdd?.sku === product.sku;

  const handleProductLinkClick = () => {
    onNavigate?.(target);
    tracking.productClick(product);
  };

  const tierPrice = product?.price_tiers?.reduce((lowest, current) => {
    if (lowest == undefined) return current;

    if (current.final_price.value > lowest.final_price.value) return current;
  }, undefined)?.final_price;

  return (
    <div className={clsx(styles.root, className)}>
      <RootLink
        className="flex min-h-200px min-w-200px"
        onClick={handleProductLinkClick}
        to={target}>
        {product.image?.childImageSharp?.gatsbyImageData && (
          <GatsbyImage
            backgroundColor="white"
            loading={imageLoading}
            className="mx-auto"
            image={product.image.childImageSharp.gatsbyImageData}
            alt={product.name || ''}
          />
        )}
        {!product.image && (
          <div
            className={clsx(
              'max-w-200px max-h-200px flex flex-1 flex-col items-center justify-center bg-gray-5'
            )}>
            <FontAwesomeIcon className="text-6xl" icon={faImage} />
          </div>
        )}
      </RootLink>
      <RootLink
        onClick={handleProductLinkClick}
        className={styles.title}
        to={target}>
        {product.name}
      </RootLink>

      {!tierPrice && (
        <PriceRange
          className="mx-auto mb-2 mt-auto"
          priceRange={product.price_range}
        />
      )}

      {tierPrice && (
        <div className="mx-auto mb-2 mt-auto">
          <MoneyView
            classes={{
              labelWith: 'text-11',
              labelWithout: 'text-11',
              priceWithout: 'text-primary text-17 md:text-28 font-regular',
              priceWith: 'font-normal text-primary text-15 md:text-17'
            }}
            labelWithout="ab"
            price={tierPrice}
          />
        </div>
      )}

      <AddToCartButton
        minAmount={product.min_sales_qty}
        disabled={!isAdding && loading}
        label={
          hasRequiredOptions ? (
            <>
              <span className="mr-2 hidden md:inline">Optionen</span>
              <span>konfigurieren</span>
            </>
          ) : undefined
        }
        loading={isAdding}
        onAdd={handleAdd}
      />
    </div>
  );
}
