import React, { ReactNode } from 'react';
import clsx from 'clsx';

import { CMSPagesLinks } from './CMSPagesLinks';
import { useStoreConfig } from '@sus-core/hooks/store/useStoreConfig';
import { PaymentIcons } from '../icon/PaymentIcons';
import { EcoHosting } from '../icon/EcoHosting';

const currentYear = new Date().getFullYear();

export function Footer({ className }: { className?: string }) {
  const { id } = useStoreConfig();

  /**
   * - links to cms pages
   * - phone and email
   * - copyright
   */

  return (
    <footer
      className={clsx(
        'grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 py-2 px-4 gap-2',
        className
      )}>
      <FooterCMSPageLinks
        storeId={id}
        title="Unternehmen"
        identifiers={[
          'unternehmen',
          'karriere',
          'zertifikate',
          'datenschutz',
          'impressum',
          'cookies',
        ]}
      />

      <FooterCMSPageLinks
        storeId={id}
        title="Hilfeservice"
        identifiers={[
          'versand',
          'zahlungsarten',
          'rücksendung',
          'faq',
        ]}></FooterCMSPageLinks>

      <FooterColumn>
        <div className="font-medium mt-4">Zahlungsarten</div>
        <ul className="flex flex-row flex-wrap m-0 p-0">
          <li>
            <PaymentIcons.Paypal />
          </li>
          <li>
            <PaymentIcons.Mastercard className="text-white-1" />
          </li>
          <li>
            <PaymentIcons.Visa />
          </li>
          <li>
            <PaymentIcons.Rechnung />
          </li>

          <li>
            <PaymentIcons.Ueberweisung />
          </li>
        </ul>
      </FooterColumn>

      <FooterColumn>
        <div className="font-medium mt-4">Kontakt</div>
        <ul>
          <li>
            <a className="text-gray-2 text-13" href="tel:00493036434470">
              +49 30 36434470
            </a>
          </li>
          <li>
            <a
              className="text-gray-2 text-13"
              href="mailto:office@schrank-und-stuhl.de">
              office@schrank-und-stuhl.de
            </a>
          </li>
          <li>
            <span className="text-gray-2 text-13">
              Mo-Fr: 08:00 - 18:00 Uhr
            </span>
          </li>
        </ul>

        <EcoHosting />
      </FooterColumn>

      <div className="col-span-2 md:col-span-4 mt-16 w-full text-right text-gray-2">
        © {currentYear} schrank-und-stuhl.de
      </div>
    </footer>
  );
}

function FooterCMSPageLinks({
  title,
  storeId,
  identifiers,
  children,
}: {
  storeId: number;
  title?: string;
  identifiers?: string[];
  children?: ReactNode;
}) {
  return (
    <FooterColumn>
      <div className="font-medium mt-4">{title}</div>
      <CMSPagesLinks
        storeId={storeId}
        classes={{ link: 'text-gray-2 text-13' }}
        identifiers={identifiers}
      />
      {children}
    </FooterColumn>
  );
}

function FooterColumn({
  children,
  className,
}: {
  children?: ReactNode;
  className?: string;
}) {
  return <section className={clsx('md:flex-1', className)}>{children}</section>;
}
