export function sequence(tasks: Array<() => Promise<any>> = []) {
  const results = Array(tasks.length).fill(null);
  return tasks
    .reduce((promise, task, idx) => {
      return promise.then(() =>
        task
          ? task().then(result => (results[idx] = result))
          : Promise.resolve()
      );
    }, Promise.resolve())
    .then(() => results);
}
