import {
  GetCart_cart_shipping_addresses,
  GetCart_cart_shipping_addresses_selected_shipping_method,
} from 'src/generated/api.types';

export function getSelectedShippingMethod(
  addresses: GetCart_cart_shipping_addresses[] = []
): GetCart_cart_shipping_addresses_selected_shipping_method | undefined {
  const methods = addresses
    .filter(address => !!address?.selected_shipping_method)
    .map(({ selected_shipping_method }) => selected_shipping_method);
  return methods.length === 1 ? methods[0] : undefined;
}
