import React from 'react';
import { subtractTax, formatPrice } from '@sus-core/utils/price';
import clsx from 'clsx';
import { graphql } from 'gatsby';

export type MoneyData = {
  readonly currency: string;
  readonly value: number;
};

export interface MoneyViewProps {
  price: MoneyData;
  onlyWithTax?: boolean;
  labelWith?: string;
  labelWithout?: string;
  adjustment?: number;
  classes?: {
    labelWithout?: string;
    priceWithout?: string;
    labelWith?: string;
    priceWith?: string;
  };
}

export function MoneyView({
  price,
  onlyWithTax,
  adjustment,
  labelWith = 'inkl.MwSt.',
  labelWithout = 'zzgl.MwSt.',
  classes,
}: MoneyViewProps) {
  const priceValue = adjustment ? price.value + adjustment : price.value;
  return (
    <>
      {!onlyWithTax && (
        <div className="flex items-baseline justify-end flex-wrap">
          <span className={clsx('mr-2', classes?.labelWithout)}>
            {labelWithout}
          </span>
          <span className={clsx(classes?.priceWithout, 'whitespace-nowrap')}>
            {formatPrice(subtractTax(priceValue), price.currency)}
          </span>
        </div>
      )}
      <div className="flex items-baseline justify-end flex-wrap">
        <span className={clsx('mr-2', classes?.labelWith)}>{labelWith}</span>
        <span className={clsx(classes?.priceWith, 'whitespace-nowrap')}>
          {formatPrice(priceValue, price.currency)}
        </span>
      </div>
    </>
  );
}

export const moneyFragment = graphql`
  fragment MoneyFragment on SUS_Money {
    currency
    value
  }
`;
